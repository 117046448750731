<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">{{readonly ? 'View' : 'Update'}} Mainboard: {{modalInfo.WLMainboardID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Controller ID - Controller Ref"
                    v-model="form.controller.selected"
                    @change="filterController"
                    :items="form.controller.items"
                    item-text="name"
                    item-value="WLControllerID"
                    :rules="form.controller.rule"
                    :loading="form.controller.loader"
                    :disabled="form.controller.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Operator.item"
                        label="Operator"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CarParkUserInputCode.item"
                        label="CP Code"
                        required
                        disabled
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    disabled
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.WLMainboardID"
                        label="MB ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Ref.item"
                        :rules="form.Ref.rule"
                        label="MB Ref."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.PoleNo.item"
                        :rules="form.PoleNo.rule"
                        label="Pole No."
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CreateDate.item"
                        label="Created Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.UpdateDate.item"
                        label="Updated Date"
                        required
                        disabled
                    ></v-text-field>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.Enabled.item"
                    :label="`${form.Enabled.item == 1 ? 'Enabled' : 'Disabled'}`"
                    :disabled="readonly"
                    ></v-switch>
                    -->
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'controller'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                CarParkUserInputCode: {item: ""},
                controller: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                Operator: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: true,
                    rule: []
                },
                Ref: {item: "", rule: []},
                PoleNo: {item: "", rule: []},
                Remark: {item: "", rule: []},
                Enabled: {item: "", rule: []},
                CreateDate: {item: "", rule: []},
                UpdateDate: {item: "", rule: []},
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.controller.rule = [
                v => !!v || 'This is required',
            ];

            this.form.Ref.rule = [
                v => !!v || 'This is required',
            ];

            this.form.Remark.rule = [
                v => !!v || 'This is required',
            ];

            this.form.PoleNo.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        filterController(){
            if (this.form.controller.selected != ""){
                let controller = this.form.controller.items.find(item => item.WLControllerID == this.form.controller.selected);
                console.log('controller', controller);
                this.form.Operator.item = controller.OperatorID;
                this.form.carpark.selected = controller.CarParkID;

                let cp = this.carpark.find(i => i.CarParkID == this.form.carpark.selected);
                this.form.CarParkUserInputCode.item = cp.CarParkUserInputCode;
            }

            else{
                this.form.Operator.item = this.modalInfo.OperatorID;
                this.form.carpark.selected = this.modalInfo.CarParkID;
                this.form.CarParkUserInputCode.item = this.modalInfo.CarParkUserInputCode;
            }
        },

        async handleValue(){
            this.form.controller.rule = [];
            this.form.Ref.rule = [];
            this.form.Remark.rule = [];

            this.form.controller.selected = "";
            this.form.carpark.selected = "";
            let carpark = [];
            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.carpark.selected = this.modalInfo.CarParkID;

            this.form.controller.items = [];
            this.form.controller.items.push({name: `Select`, WLControllerID: ''});
            for (const [index, item] of this.controller.entries()){
                this.form.controller.items.push({
                    name: `${item.WLControllerID} - ${item.WLControllerRef}`,
                    WLControllerID: item.WLControllerID,
                    OperatorID: item.OperatorID,
                    CarParkID: item.CarParkID
                });
            }
            this.form.controller.selected = this.modalInfo.WLControllerID;
            this.form.Operator.item = this.modalInfo.OperatorID;
            this.form.Remark.item = this.modalInfo.WLMainboardRemark;
            this.form.Enabled.item = this.modalInfo.WLMainboardEnabled;
            this.form.Ref.item = this.modalInfo.WLMainboardRef;
            this.form.PoleNo.item = this.modalInfo.PoleDesc;
            this.form.CarParkUserInputCode.item = this.modalInfo.CarParkUserInputCode;
            this.form.CreateDate.item = this.modalInfo.WLMainboardCreatedDateTime;
            this.form.UpdateDate.item = this.modalInfo.WLMainboardUpdatedDateTime;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>