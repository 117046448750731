<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Duit Now</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Operator"
                    label="Operator"
                    v-model="operator.selected"
                    @change="execFilter('operator')"
                    :items="operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="operator.rule"
                    :loading="operator.loader"
                    :disabled="operator.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('dn_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Virtual Account
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? page * 100 : 1}} - {{page > 1 ? items.length + 100 : items.length}} of {{items.length}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('dn_update')">
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.ID}}</a>
                                </template>
                                <span>Update</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.ID}}</a>
                                </template>
                                <span>View</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.OperatorName}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td style="white-space: pre">{{props.item.CarParkName}}</td>
                            <td>{{props.item.ControllerID}}</td>
                            <td>{{props.item.ControllerType}}</td>
                            <td>{{props.item.VirtualAccNo}}</td>
                            <td>{{props.item.VirtualAccName}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.UpdatedDateTime}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull" :controller="controller.itemsFull"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull" :controller="controller.itemsFull" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateVirtualAccount';
import edit from '.././modals/UpdateVirtualAccount';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Duit Now',
    title: 'Duit Now',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },

                {
                text: 'Setting',
                disabled: true,
                href: '#',
                },

                {
                text: 'Duit Now',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*
            #, ID, CPO ID, Operator, CP ID, Car Park, Controller ID, Controller Type, VA No., VA Name, Is Default, Created Date, Updated Date
            */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'RelationID', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'Operator', value: 'OperatorName', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Controller ID', value: 'TID', width: '3%' },
                { text: 'Controller Type', value: 'TID', width: '3%' },
                { text: 'VA No.', value: 'TID', width: '3%' },
                { text: 'VA Name', value: 'TID', width: '3%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'UpdatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            controller: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            productcode: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: []
        }
    },

    components: {create, edit, alert, alertbox2},

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listAll();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    async created(){
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.listController();
        await this.listAll(1);
    },

    methods: {
        async listAll(pg){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getQRIDList", {page: pg > 0 ? this.page = pg : this.page, search: this.search, operatorid: this.operator.selected, carparkid: this.carpark.selected});

                this.services = response;
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count || dt.length;
                const events = [];

                Number.prototype.percent = function() {
                    return +(this * 100).toFixed(2);
                }

                dt.forEach((doc, i) => {
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.CreatedDateTime = doc.CreatedDateTime == null ? "N/A" : moment(new Date(doc.CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    appData.UpdatedDateTime = doc.UpdatedDateTime == null ? "N/A" : moment(new Date(doc.UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    appData.CarParkID = appData.CarParkID == null ? "N/A" : appData.CarParkID;
                    appData.CarParkName = appData.CarParkName == null ? "N/A" : appData.CarParkName;
                    appData.OperatorID = appData.OperatorID == null ? "N/A" : appData.OperatorID;
                    appData.OperatorName = appData.OperatorName == null ? "N/A" : appData.OperatorName;
                    appData.ControllerID = appData.ControllerID == null ? "N/A" : appData.ControllerID;
                    appData.ControllerType = appData.ControllerType == null ? "N/A" : appData.ControllerType;
                    
                    events.push(appData);
                })

                this.items = events;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async listController(){
            this.controller.items = [];
            this.controller.selected = "";
            let self = this;

            try {
                this.controller.loader = true;
                this.controller.disable = true;

                let selectedfilters = {
                        operator: this.operator.selected,
                        carpark: this.carpark.selected
                    };

                let fil = Object.entries(selectedfilters).filter((item) => item[1] !== "");
                let selectedfilter = fil[fil.length - 1] || "";

                console.log('selectedfilters', selectedfilter);

                if (this.controller.itemsFull.length > 0){
                    let type = '';
                    if (selectedfilter[0] == 'operator') type = "OperatorID";
                    if (selectedfilter[0] == 'carpark') type = "CarParkID";

                    let controller = this.controller.itemsFull.filter(item => {
                    return item[type] == selectedfilter[1];
                    });

                    this.controller.items.push({name: "All Controllers", WLControllerID: ""});

                    for(var i=0; i<controller.length; i++){
                        controller[i].name = `${controller[i].WLControllerID} - ${controller[i].WLControllerRef}`;
                        this.controller.items.push({name: controller[i].name, WLControllerID: controller[i].WLControllerID});
                    }
                }

                else{
                    let response = await this.$store.dispatch("getWheelLockController", {rowresultsperpage: null});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.controller.items.push({name: "All Controllers", WLControllerID: ""});
                        this.controller.itemsFull.push({name: "All Controllers", WLControllerID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].WLControllerID} - ${dt[i].WLControllerRef}`;
                            this.controller.items.push({name: dt[i].name, WLControllerID: dt[i].WLControllerID});
                            this.controller.itemsFull.push(dt[i]);
                        }
                    }
                }

                this.controller.selected = "";
                }

                this.controller.loader = false;
                this.controller.disable = false;
            }
            
            catch (err) {
                console.log(err);
            }
        },

        async getProductCodeFull(){
            let list = [];
            this.productcode.items = [];
            this.productcode.selected = "";

            try{
                this.productcode.loader = true;
                this.productcode.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getAllProductCode", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.productcode.items.push({name: "All Operators", ProductCode_Code: ""});
                    this.productcode.itemsFull.push({name: "All Operators", ProductCode_Code: ""});

                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].ProductCode_Code} - ${dt[i].ProductCode_Name}`;
                    this.productcode.items.push({name: dt[i].name, ProductCode_Code: dt[i].ProductCode_Code});
                    this.productcode.itemsFull.push(dt[i]);
                }

                this.productcode.selected = this.productcode.items[0].ProductCode_Code;
                }

                //this.operator.itemsFull = dt;

                this.productcode.loader = false;
                this.productcode.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addOperator(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "ControllerID": v.controller.selected,
                    "QRID": v.QRID.item,
                    "ControllerType": v.type.selected,
                    "VirtualAccName": v.VirtualAccName.item,
                    "VirtualAccNo": v.VirtualAccNo.item,
                    "Remark": v.Remark.item
                };
                let response = await this.$store.dispatch("createQRID", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listAll(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Virtual Account Created!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async operatorRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteQRID", {ID: this.ma.modalInfo.ID})
                if (response.data.code === 'AP000'){
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Virtual Account has been removed!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async operatorUpdate(v){
            let req = {
                "ID": this.ma.modalInfo.ID,
                "ControllerID": v.controller.selected,
                "QRID": v.QRID.item,
                "ControllerType": v.type.selected,
                "VirtualAccName": v.VirtualAccName.item,
                "VirtualAccNo": v.VirtualAccNo.item,
                "Remark": v.Remark.item
            };

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("editQRID", req)
                if (response.data.code === 'AP000'){
                        this.items = [];
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Virtual Account has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async operatorUpdateMainQRID(v){
            let req = {
                "QRID": v.QRID.item,
            };

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateQRIDisDefault", req)
                if (response.data.code === 'AP000'){
                        this.items = [];
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Virtual Account Main QRID has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log('aa', value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addOperator(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.operatorUpdate(value);
            }

            if (value.flag === 'update main qrid'){
                this.ma.modal_update = false;
                this.operatorUpdateMainQRID(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.operatorRemove();
                }
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.operatorRemove();
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAll(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enabled'}
                    else if (val === 0){return 'Disabled'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        mobileDisplayStatus(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Yes'}
                    else if (val === 0){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },
    }
}
</script>