<template>
  <v-container fill-height="fill-height" style="opacity: .91">
        <v-layout align-center="align-center" justify-center="justify-center">
                <v-fade-transition mode="out-in">

          <v-flex class="login-form text-xs-center"> 
            <div class="display-1 mb-3 white--text">
              <v-row dense>
                    <v-col cols="2">
                        <img style="max-width: 100%; height: auto" src="../assets/logo.png" />
                    </v-col>
                    <v-col cols="10" style="align-self: center">
                        <img style="max-width: 100%; height: auto" src="../assets/snatch_word_yellow.png" />
                    </v-col>
                </v-row>Welcome to Snatch
            </div>
            <v-card light="light">
              <v-card-text>
                <div class="subheading">
                  <template>Create Password</template>
                </div>
                <v-form ref="loginForm" action="#" @submit.prevent="validateBeforeSubmit" lazy-validation>
                  <v-text-field readonly required :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Please enter an email address']" v-model="user.name" light="light" prepend-icon="fa fa-user" label="Email" type="text"></v-text-field>
                  <v-text-field required :rules="[v => !!v || 'Password is required']" v-model="user.password" light="light" prepend-icon="fa fa-lock" label="Password" type="password"></v-text-field>
                  <v-text-field required :rules="[v => !!v || 'Password is required', v => confirmPword || 'Please ensure your password matches']" v-model="user.cpassword" light="light" prepend-icon="fa fa-lock" label="Confirm Password" type="password"></v-text-field>
                  <v-checkbox v-if="options.isLoggingIn" v-model="options.shouldStayLoggedIn" light="light" label="Stay logged in?" hide-details="hide-details"></v-checkbox>
                  <v-btn color="primary" block="block" type="submit" :loading="loading" :disabled="loading" class="mt-2">
                        Create Password
                        <template v-slot:loader>
                            <span>Loading...</span>
                        </template>
                  </v-btn>                 
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
                </v-fade-transition>
          <v-footer app="app">
      <v-flex class="caption">Copyright By Snatch Park Bizsolutions Sdn Bhd © 2021</v-flex>
    </v-footer>
        </v-layout>
        <v-dialog
        v-model="dialog"
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Logging In...
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
        <v-snackbar
        v-model="toaster"
        :color="toasterColor"
        top
        >
        {{ toastertxt }}
        </v-snackbar>
      </v-container>
</template>

<script>
import timer from '../assets/js/sleeper';

export default {
    name: 'changepassword',

    data(){
        return {
            options: {
                isLoggingIn: '',
                shouldStayLoggedIn: true,
            },

            user: {
                name: '',
                password: '',
                cpassword: ''
            },

            loading: false,
            dialog: false,
            toaster: false,
            toastertxt: '',
            toasterColor: ''
        }
    },

    created(){
        //this.test1();
        this.verifyEmail();
    },

    computed: {
      confirmPword(){
        if (this.user.password !== this.user.cpassword){
            return false;
        }

        else{
            return true;
        }
     }
    },

    methods: {
        /*
        test1(){
                if (!window.Notification) {
        console.log('Browser does not support notifications.');
    } else {
        // check if permission is already granted
        if (Notification.permission === 'granted') {
            // show notification here
            var notify = new Notification('From Snatch Park', {
                body: 'How are you doing?',
                icon: 'https://img.icons8.com/ios/50/000000/parking.png',
            });
        } else {
            // request permission from user
            Notification.requestPermission().then(function (p) {
                if (p === 'granted') {
                    // show notification here
                    var notify = new Notification('Hi there!', {
                        body: 'How are you doing?',
                        icon: 'https://bit.ly/2DYqRrh',
                    });
                } else {
                    console.log('User blocked notifications.');
                }
            }).catch(function (err) {
                console.error(err);
            });
        }
    }
        },
        */
        async createPassword(){
            this.loading = true
            this.dialog = true

            try{
                let response = await this.$store.dispatch("createPassword", {
                                    username: atob(this.$route.query.email),
                                    verifykey: this.$route.query.verifykey,
                                    password: this.user.password,
                                    confirmpassword: this.user.cpassword
                                    })

                await timer.sleep(100);
                if (response.data.code == 'AP000')
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = "Password created! Directing you to login page";
                    this.toasterColor = 'green'
                    await timer.sleep(800);
                    this.$router.push({ name: "login" });
                }

                else if (response.data.code === '303')
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = "Incorrect Login Credentials!";
                    this.toasterColor = 'red'
                }

                else
                {
                    this.loading = false;
                    this.dialog = false;
                    this.toaster = true;
                    this.toastertxt = "Something went wrong!";
                    this.toasterColor = 'red'
                }
            }

            catch(err){
                this.loading = false;
                this.dialog = false;
            }
        },

        async verifyEmail(){
            try{
                let response = await this.$store.dispatch("verifyEmail", {
                                    email: this.$route.query.email,
                                    verifykey: this.$route.query.verifykey
                                    })

                if (response.data.code == '000')
                {
                    if (this.$route.query.email){
                        this.user.name = atob(this.$route.query.email);
                    }
                }

                else if (response.data.code === '302')
                {
                    this.toaster = true;
                    this.toastertxt = "1 hour timer. The verification link has expired. Please contact support"
                    this.toasterColor = 'red';
                }

                else
                {
                    this.toaster = true;
                    this.toastertxt = "Something went wrong!"
                    this.toasterColor = 'red';
                }
            }

            catch(err){}
        },

        validateBeforeSubmit() {
            if (this.$refs.loginForm.validate()) {
                this.createPassword();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-form{
    max-width: 500px !important;
}
</style>