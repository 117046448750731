<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Controller Ref: {{modalInfo.RefControllerID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay
                :absolute="true"
                :opacity="1"
                :z-index="999"
                :value="modal_loader"
                color="#D2D7DF"
                >
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-overlay>
            <v-row v-if="items.length > 0">
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].GateControllerID"
                        label="Controller ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].RefControllerID"
                        label="Controller Ref."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].lastConnectDate"
                        label="Date Last Connected"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].lastDisconnectDate"
                        label="Date Last Disconnected"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].lastSyncRequestDate"
                        label="Date Last Sync Request"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].lastSyncDate"
                        label="Date Last Sync"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="12">
                    <span class="text-body-2">No entry found for this parking entry</span>
                </v-col>
            </v-row>
              <!--
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ParkingEntryID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.CurrentCustomerMobile}}</td>
                            <td>{{props.item.ParkingEntryDateTime}}</td>
                            <td>{{props.item.Duration}}</td>
                            <td>{{props.item.ExitMethod}}</td>
                            <td>{{props.item.ValuePassID}}</td>
                            <td>{{props.item.ValuePassUnitID}}</td>
                            <td>{{props.item.ValuePassName}}</td>
                            <td>{{props.item.TotalTokens}}</td>
                            <td>{{props.item.Amount_CurrentFees}}</td>
                            <td>{{props.item.Amount_Due}}</td>
                            <td>{{props.item.Amount_FutureFees}}</td>
                        </tr>
                    </template>
            </v-data-table>
            -->
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'Parking ID', value: 'ParkingEntryID' },
                { text: 'CP ID', value: 'CarParkID' },
                { text: 'Car Park', value: 'CarParkName' },
                { text: 'Mobile No.', value: 'CurrentCustomerMobile'},
                { text: 'Date Of Entry', value: 'ParkingEntryDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Parking Duration (dd:hh:mm)', value: 'Duration' },
                { text: 'Exit Method', value: 'ExitMethod' },
                { text: 'VP ID', value: 'ValuePassID' }, //need to update to actual param
                { text: 'VP Unit ID', value: 'ValuePassUnitID' }, //need to update to actual param
                { text: 'Value Pass Name', value: 'ValuePassName' },
                { text: 'Total Parking Tokens', value: 'TotalTokens' }, //need to update to actual param
                { text: 'Current Fees (RM)', value: 'Amount_CurrentFees' },
                { text: 'Pending Paid (RM)', value: 'Amount_Due' },
                { text: 'Next 2 Hours Fees (RM)', value: 'Amount_FutureFees' }, //need to update to actual param
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1,
            modal_loader: true,
            amount_due_color: ''
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];
            this.tableloading = true;
            this.modal_loader = true;

            let response = await this.$store.dispatch('getGateControllerByID', {gatecontrollerid: this.modalInfo.GateControllerID})
            if (response.data.code == "AP000"){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    /*
                    35. Controller ID, Controller Ref., Date Last Connected, Date Last Disconnected, Date Last Sync Request, Date Last Sync -> display N/A if no value -> {{url}}/api/gatecontroller/view/bygatecontrollerid :warning:
                    */
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].lastConnectDate = dt[i].lastConnectDate == null ? "N/A" : moment.unix(dt[i].lastConnectDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastDisconnectDate = dt[i].lastDisconnectDate == null ? "N/A" : moment.unix(dt[i].lastDisconnectDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastSyncRequestDate = dt[i].lastSyncRequestDate == null ? "N/A" : moment.unix(dt[i].lastSyncRequestDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastSyncDate = dt[i].lastSyncDate == null ? "N/A" : moment.unix(dt[i].lastSyncDate).format('DD-MM-YYYY hh:mm:ss A');
                }

                this.items = dt;
            }

            this.tableloading = false;
            this.modal_loader = false;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>