<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Create BayLock</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    @change="execFilter('operator')"
                    :items="form.operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    :disabled="form.operator.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    @change="execFilter('carpark')"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    >
                    </v-autocomplete>
                    <!--
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Controller ID - Controller Ref"
                    v-model="form.controller.selected"
                    :items="form.controller.items"
                    item-text="name"
                    item-value="WLControllerID"
                    :rules="form.controller.rule"
                    :loading="form.controller.loader"
                    :disabled="form.controller.disable"
                    >
                    </v-autocomplete>
                    -->
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Mainboard ID - Mainboard Ref *"
                    v-model="form.mainboard.selected"
                    :items="form.mainboard.items"
                    item-text="name"
                    item-value="WLMainboardID"
                    :rules="form.mainboard.rule"
                    :loading="form.mainboard.loader"
                    :disabled="form.mainboard.disable"
                    >
                    </v-autocomplete>
                    <!--
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Bay *"
                    v-model="form.bay.selected"
                    :items="form.bay.items"
                    item-text="name"
                    item-value="BayID"
                    :rules="form.bay.rule"
                    :loading="form.bay.loader"
                    :disabled="form.bay.disable"
                    >
                    </v-autocomplete>
                    -->
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="form.GateToken.item"
                        :rules="form.GateToken.rule"
                        label="Tokens"
                        required
                    ></v-text-field>
                    -->
                    <v-text-field
                        dense
                        outlined
                        v-model="form.wllockref.item"
                        :rules="form.wllockref.rule"
                        label="BL Ref. *"
                        required
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Baylock Type *"
                    v-model="form.Type.item"
                    :items="form.Type.list"
                    item-text="text"
                    item-value="value"
                    :rules="form.Type.rule"
                    :loading="form.Type.loader"
                    :disabled="form.Type.disable"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.remark.item"
                        :rules="form.remark.rule"
                        label="Remark"
                        required
                    ></v-text-field>
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="form.TokenRef.item"
                        :rules="form.TokenRef.rule"
                        label="Tokens Ref."
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.GateType.item"
                        :rules="form.GateType.rule"
                        label="Type"
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.PoleDesc.item"
                        :rules="form.PoleDesc.rule"
                        label="Pole No."
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Entry/Exit"
                    v-model="form.IsEntryGate.selected"
                    :items="form.IsEntryGate.items"
                    item-text="name"
                    item-value="ID"
                    :rules="form.IsEntryGate.rule"
                    >
                    </v-autocomplete>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="#25283D"
                    class="col-1 pl-0"
                    v-model="form.Enabled.item"
                    :label="`${form.Enabled.item == 1 ? 'Enabled' : 'Disabled'}`"
                    ></v-switch>
                    -->
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator', 'controller', 'bays', 'mainboard'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                GateToken: {item: "", rule: []},
                wllockref: {item: "", rule: []},
                TokenRef: {item: ""}, //wait backend
                Operator: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                controller: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                mainboard: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                bay: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                remark: {item: "", rule: []},
                Enabled: {item: "", rule: []},
                Type:{item: 1, rule: [], list:[{text: 'Wired', value: 1}, {text: 'Wireless', value: 2}]}
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.mainboard.rule = [
                v => !!v || 'This is required',
            ];

            this.form.bay.rule = [
                v => !!v || 'This is required',
            ];

            this.form.wllockref.rule = [
                v => !!v || 'This is required',
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.form.mainboard.rule = [];
            this.form.wllockref.rule = [];
            this.form.bay.rule = [];
            this.form.remark.rule = [];

            this.form.wllockref.item = "";
            this.form.remark.item = "";

            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            let filteredoperator = this.operator.filter(item => item.OperatorID !== "");
            for (const [index, item] of filteredoperator.entries()){
                operator.push(item);
            }

            this.form.operator.items = operator;
            this.form.operator.selected = "";

            this.form.carpark.selected = "";
            let carpark = [];
            carpark.push({name: 'Select', CarParkID: '', OperatorID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.carpark.selected = "";

            this.form.bay.selected = "";
            for (const [index, item] of this.bays.entries()){
                let operator = this.carpark.find(i => i.CarParkID === item.CarParkID);
                let operatorid = operator == undefined ? "N/A" : operator.OperatorID;
                this.form.bay.itemsFull.push({name: `${item.BayID}`, BayID: item.BayID, CarParkID: item.CarParkID, OperatorID: operatorid});
            }

            let bays = [];
            bays.push({name: `Select`, BayID: ''});
            for (const [index, item] of this.bays.entries()){
                bays.push({name: `${item.BayID}`, BayID: item.BayID});
            }

            this.form.bay.items = bays;
            this.form.bay.selected = "";

            this.form.mainboard.selected = "";
            let mainboard = [];
            mainboard.push({name: `Select`, WLMainboardID: ''});
            for (const [index, item] of this.mainboard.entries()){
                mainboard.push({name: `${item.WLMainboardID} - ${item.WLMainboardRef}`, WLMainboardID: item.WLMainboardID});
            }

            this.form.mainboard.items = mainboard;
            this.form.mainboard.selected = "";
        },

        execFilter(key){
            if (key == 'operator')
            this.form.carpark.selected = "";

            let searchitems = {
                OperatorID: this.form.operator.selected,
                CarParkID: this.form.carpark.selected,
            };

            if (this.form.operator.selected == '') delete searchitems.OperatorID;
            if (this.form.carpark.selected == '') delete searchitems.CarParkID;

            let carparksearchitems = {
                OperatorID: this.form.operator.selected,
            };

            if (this.form.operator.selected == '') delete carparksearchitems.OperatorID;

            let carpark = this.carpark.filter(function(item) {
                for (var key in carparksearchitems) {
                    if (item[key] === undefined || item[key] != carparksearchitems[key])
                    return false;
                }
                return true;
            });

            let carparkArr = [];
            carparkArr.push({name: `Select`, CarParkID: ''});
            for (const [index, item] of carpark.entries()){
                carparkArr.push({name: `${item.CarParkID} - ${item.CarParkName}`, CarParkID: item.CarParkID});
            }

            this.form.carpark.items = carparkArr;

            let mainboard = this.mainboard.filter(function(item) {
                for (var key in searchitems) {
                    if (item[key] === undefined || item[key] != searchitems[key])
                    return false;
                }
                return true;
            });

            let mainboardArr = [];
            mainboardArr.push({name: `Select`, WLMainboardID: ''});
            for (const [index, item] of mainboard.entries()){
                mainboardArr.push({name: `${item.WLMainboardID} - ${item.WLMainboardRef}`, WLMainboardID: item.WLMainboardID});
            }
            this.form.mainboard.selected = "";
            this.form.mainboard.items = mainboardArr;

            let bay = this.form.bay.itemsFull.filter(function(item) {
                for (var key in searchitems) {
                    if (item[key] === undefined || item[key] != searchitems[key])
                    return false;
                }
                return true;
            });
            
            let bayArr = [];
            bayArr.push({name: `Select`, BayID: ''});
            for (const [index, item] of bay.entries()){
                bayArr.push({name: `${item.BayID}`, BayID: item.BayID});
            }
            this.form.bay.selected = "";
            this.form.bay.items = bayArr;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>