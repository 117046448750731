<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Reader</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <vc-date-picker
                    :disabled-dates='dayplusone'
                     :key="counter1" v-model="starttime.item" mode="date" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="listItems(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="From Date - To Date"
                                outlined
                                autocomplete="off"
                                hint="You can only select date range not more than 1 month."
                                persistent-hint
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
                <!--
                <v-col cols="12" md="6">
                    <dpicker v-if="show" :datetime="starttime" :mode="'date'" @date="dateValStartTime" label="From" :width="'33px'"></dpicker>
                </v-col>
                <v-col cols="12" md="6">
                    <dpicker v-if="show" :datetime="endtime" :mode="'date'" @date="dateValEndTime" label="To" :width="'33px'"></dpicker>
                </v-col>
                -->
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12"><v-btn outlined tile small color="primary" type="button" v-on:click="getForReport" :disabled="counter1 == 0 || reportloader" :loading="reportloader"><v-icon x-small left>fa fa-download</v-icon> Download Report
                <template v-slot:loader>
                            <span>Downloading Report...</span>
                        </template>
                </v-btn>
                </v-col>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.RefNoByAction}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre">{{props.item.CarParkName}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.mobile}}</td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.Action}}</td>
                            <td>{{props.item.InvoiceReceiptURLText}}</td>
                            <td>{{props.item.Amount}}</td>
                            <td>{{props.item.AmountTax}}</td>
                            <td>{{props.item.NetAmount}}</td>
                            <td>{{props.item.SPFeeAmount}}</td>
                            <td>{{props.item.OperatorFinalAmount}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSystem';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF2';
import dpicker from "../DatePickerComponent";
import XLSX from 'xlsx';

export default {
    name: 'All Products',
    title: 'All Products',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Report',
                disabled: true,
                href: '#',
                },

                {
                text: 'Operator Remittance',
                disabled: true,
                href: '#',
                },

                {
                text: 'Reader',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            /*
            #, Ref. No., CP ID, CP Code, Car Park, Name, Mobile No., Email, Transaction Date, Product, Bill No., Amount (RM), SST (RM), Net Amount (RM), SP Fees (RM), Total Remittance (RM)
            */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Ref. No.', value: 'RefNoByAction', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Name', value: 'name', width: '3%' },
                { text: 'Mobile No.', value: 'mobile', width: '5%' },
                { text: 'Email', value: 'email', width: '3%' },
                { text: 'Transaction Date', value: 'CreatedDateTime', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Product', value: 'Action', width: '5%' },
                { text: 'Bill No.', value: 'InvoiceReceiptURL', width: '3%' },
                { text: 'Amount (RM)', value: 'Amount', width: '5%' },
                { text: 'SST (RM)', value: 'AmountTax', width: '3%' },
                { text: 'Net Amount (RM)', value: 'NetAmount', width: '3%' },
                { text: 'SP Fees (RM)', value: 'SPFeeAmount', width: '3%' },
                { text: 'Total Remittance (RM)', value: 'OperatorFinalAmount', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_pdf: '',
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            starttime: {item: {start: '', end: ''}},
            endtime: {item: ''},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            show: false,
            counter1: 0,
            dataforexport: [],
            reportloader: false
        }
    },

    components: {create, alert, alertbox2, viewpdf, dpicker},

    async created(){
        await this.getCarParkFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        //await this.setTime();
        await this.listItems(1);
        this.show = true;
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

            else{
                return '';
            }
        },
    },

    methods: {
        async listItems(pg){
            try{
                this.tableloading = true;
                await this.setTime();
                this.counter1++;
                let response = await this.$store.dispatch("getOperatorRemittanceReader", {page: pg > 0 ? this.page = pg : this.page, search: this.search, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, operatorid: this.operator.selected, carparkid: this.carpark.selected });

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = moment(dt[i].CreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].name = dt[i].name == null ? 'N/A' : dt[i].name;
                    dt[i].mobile = dt[i].mobile == null ? 'N/A' : dt[i].mobile;
                    dt[i].email = dt[i].email == null || dt[i].email == "" ? 'N/A' : dt[i].email;
                    dt[i].Amount = Number(dt[i].Amount).toFixed(2);
                    dt[i].AmountTax = Number(dt[i].AmountTax).toFixed(2);
                    dt[i].NetAmount = Number(dt[i].NetAmount).toFixed(2);
                    dt[i].SPFeeAmount = Number(dt[i].SPFeeAmount).toFixed(2);
                    dt[i].OperatorFinalAmount = Number(dt[i].OperatorFinalAmount).toFixed(2);

                    let url = String(dt[i].InvoiceReceiptURL);
                    url = url.substring(url.lastIndexOf('/') + 1);
                    dt[i].InvoiceReceiptURLText = url.split('.')[0];
                    dt[i].urltext = dt[i].InvoiceReceiptURLText; //for modal pdf saving file
                    //replace(/\s/g,'') remove any literal whitespace
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                //await this.getForReport();
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getForReport(){
            this.reportloader = true;
            let response = await this.$store.dispatch("getOperatorRemittanceReader", {page: 1, search: this.search, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, rowresultsperpage: this.totalrecords, operatorid: this.operator.selected, carparkid: this.carpark.selected });
            let dt = response.data.record;
            const arr = [];
            
            dt.forEach((doc, i) => {
                //ID, Ref No., Mobile ID, Name, Mobile No., Email, Transaction Date, Free Tokens, Amount (RM)
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.CreatedDateTime = doc.CreatedDateTime == null ? "N/A" : moment(new Date(doc.CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    let url = String(appData.InvoiceReceiptURL);
                    url = url.substring(url.lastIndexOf('/') + 1);
                    appData.InvoiceReceiptURLText = url.split('.')[0];
                    appData.urltext = appData.InvoiceReceiptURLText; //for modal pdf saving file
                    appData.Amount = Number(appData.Amount).toFixed(2);
                    appData.AmountTax = Number(appData.AmountTax).toFixed(2);
                    appData.NetAmount = Number(appData.NetAmount).toFixed(2);
                    appData.SPFeeAmount = Number(appData.SPFeeAmount).toFixed(2);
                    appData.OperatorFinalAmount = Number(appData.OperatorFinalAmount).toFixed(2);
                    appData.email = appData.email == null || appData.email == "" ? 'N/A' : appData.email;

                    arr.push({
                        row: appData.row,
                        RefNoByAction: appData.RefNoByAction,
                        CarParkID: appData.CarParkID,
                        CarParkUserInputCode: appData.CarParkUserInputCode,
                        CarParkName: appData.CarParkName,
                        name: appData.name,
                        mobile: appData.mobile,
                        email: appData.email,
                        CreatedDateTime: appData.CreatedDateTime,
                        Action: appData.Action,
                        InvoiceReceiptURLText: appData.InvoiceReceiptURLText,
                        Amount: appData.Amount,
                        AmountTax: appData.AmountTax,
                        NetAmount: appData.NetAmount,
                        SPFeeAmount: appData.SPFeeAmount,
                        OperatorFinalAmount: appData.OperatorFinalAmount,
                    });
            })

            let total1 = arr.reduce(function (acc, obj) { return acc + Number(obj.Amount); }, 0);
            let total2 = arr.reduce(function (acc, obj) { return acc + Number(obj.AmountTax); }, 0);
            let total3 = arr.reduce(function (acc, obj) { return acc + Number(obj.NetAmount); }, 0);
            let total4 = arr.reduce(function (acc, obj) { return acc + Number(obj.SPFeeAmount); }, 0);
            let total5 = arr.reduce(function (acc, obj) { return acc + Number(obj.OperatorFinalAmount); }, 0);

            arr.push({
                        row: "",
                        RefNoByAction: "",
                        CarParkID: "",
                        CarParkUserInputCode: "",
                        CarParkName: "",
                        name: "",
                        mobile: "",
                        email: "",
                        CreatedDateTime: "",
                        Action: "",
                        InvoiceReceiptURLText: "Grand Total",
                        Amount: total1.toFixed(2),
                        AmountTax: total2.toFixed(2),
                        NetAmount: total3.toFixed(2),
                        SPFeeAmount: total4.toFixed(2),
                        OperatorFinalAmount: total5.toFixed(2),
                    });

            this.dataforexport = arr;
            this.reportloader = false;
            this.onexport();
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                this.pdfurl = row.InvoiceReceiptURL;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
            }
        },

        /*
        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },
        */

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.listItems(1);
            }

            if (type == 'carpark'){
                await this.listItems(1);
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        /*
        dateValStartTime(value){
            this.starttime.item = value;
            this.execFilter('date');
        },

        dateValEndTime(value){
            this.endtime.item = value;
            this.execFilter('date');
        },
        */

        setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss') : moment(new Date(moment(new Date()).subtract(31, 'days')).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss');
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime).add(1, 'days'));
                this.starttime.item.end = new Date(endtime);
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            this.starttimeforapi = starttime;
            this.endtimeforapi = endtime;

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null
        },

        cleardayplusone(v){
            let dte2 = new Date(moment(v.start).add(31, 'days'));
            this.dayplusone = [
                        {
                        start: dte2,
                        end: null
                        },
                    ];
        },

        isToday() {
            return moment(new Date()).format('DD-MM-YYYY');
        },

        onexport () { // On Click Excel download button
        // export json to Worksheet of Excel
        // only array possible
        var eHeaders = XLSX.utils.json_to_sheet(this.dataforexport, { origin: 'A6'})
        /*
            { text: '#', value: 'row', width: '3%' },
                { text: 'Ref. No.', value: 'RefNoByAction', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Name', value: 'name', width: '3%' },
                { text: 'Mobile No.', value: 'mobile', width: '5%' },
                { text: 'Email', value: 'email', width: '3%' },
                { text: 'Transaction Date', value: 'CreatedDateTime', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Product', value: 'Action', width: '5%' },
                { text: 'Bill No.', value: 'InvoiceReceiptURL', width: '3%' },
                { text: 'Amount (RM)', value: 'Amount', width: '5%' },
                { text: 'SST (RM)', value: 'AmountTax', width: '3%' },
                { text: 'Net Amount (RM)', value: 'NetAmount', width: '3%' },
                { text: 'SP Fees (RM)', value: 'SPFeeAmount', width: '3%' },
                { text: 'Total Remittance (RM)', value: 'OperatorFinalAmount', width: '3%' },
            */
        // A workbook is the name given to an Excel file
            eHeaders.A6.v = '#';
            eHeaders.B6.v = 'Ref. No.';
            eHeaders.C6.v = 'CP ID';
            eHeaders.D6.v = 'CP Code';
            eHeaders.E6.v = 'Car Park';
            eHeaders.F6.v = 'Name';
            eHeaders.G6.v = 'Mobile No.';
            eHeaders.H6.v = 'Email';
            eHeaders.I6.v = 'Transaction Date';
            eHeaders.J6.v = 'Product';
            eHeaders.K6.v = 'Bill No.';
            eHeaders.L6.v = 'Amount (RM)';
            eHeaders.M6.v = 'SST (RM)';
            eHeaders.N6.v = 'Net Amount (RM)';
            eHeaders.O6.v = 'SP Fees (RM)';
            eHeaders.P6.v = 'Total Remittance (RM)';

        var wb = XLSX.utils.book_new() // make Workbook of Excel
        const sheet_name_list = wb.SheetNames;
        console.log('sheet_name_list', sheet_name_list);


        XLSX.utils.sheet_add_aoa(eHeaders, [["Snatch Park Bizsolutions Sdn Bhd"]], {origin: 'A1'});
        XLSX.utils.sheet_add_aoa(eHeaders, [["All Products Report"]], {origin: 'A3'});
        XLSX.utils.sheet_add_aoa(eHeaders, [[`Date: From ${moment(new Date(this.starttime.item.start)).format('DD-MM-YYYY')} To ${moment(new Date(this.starttime.item.end)).format('DD-MM-YYYY')}`]], {origin: 'A4'});

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, eHeaders, 'AllProductsReport') // sheetAName is name of Worksheet
        // export Excel file
        XLSX.writeFile(wb, 'AllProductsReport_'+this.isToday()+'.csv') // name of the file is 'book.xlsx'
        },
    }
}
</script>