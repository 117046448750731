<template>
  <v-row justify="center">
    <v-dialog v-model="dialog_local" max-width="450">
      <v-form ref="es" action="#" @submit.prevent="beforeEditSystem" lazy-validation>
      <v-card>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Wheel Lock Settings ID {{modalInfo.WLWheelLockID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <!-- <span class="caption" style="color: red"><sup>*</sup> Reset Controller is disabled for Controller ID's with UUID</span><br/> -->
                <v-label>Reset Controller</v-label>
                  <v-switch
                    v-model="switch1"
                    color="success"
                    class="col-1 pl-0"
                    :label="`Reset: ${switch1 === true ? 'Yes' : 'No'}`"
                    ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon> Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    </v-dialog>
  </v-row>
</template>

<script>
import alertbox from './Alert';
  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialog_local: false,
            innerModal: false,
            items: {
                name: '',
                connectUrl: '',
                disconnectUrl: '',
                connectToken: '',
                disconnectToken: '',
                referenceID: '',
                id: '',
                uuid: null,
                disableuuid: false,
                s: {
                    system: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: [
                    v => !!v || 'System is required',
                    ]
                },

                m: {
                    master: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: [
                    v => !!v || 'Master is required',
                    ]
                },
                flag: true,
            },
            ma: {
                modal_action_type: '',
                modalInfo: [],
                customText: [],
            },

            switch1: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'items.uuid': {
        handler: function(value) {
        /*
            if (value != null)
            {
                this.items.disableuuid = true;
            }

            else
                this.items.disableuuid = false;
        */
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.switch1 = false;
            this.items.id = this.modalInfo.WLWheelLockID;
            this.items.uuid = this.modalInfo.WLWheelLockUUID;
        },

        beforeEditSystem(){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.es.validate()) {
                 if (self.switch1){
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = 'reset';
                 }

                 else{
                    self.swal.scolor = 'orange';
                    self.swal.notification = true;
                    self.swal.message = "Switch to Yes to allow Save action";
                 }
                }
            });
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
            let items = {flag: 'reset', switch: this.switch1, wllockid: this.modalInfo.WLWheelLockID};
            this.$emit('confirm', items);

            this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>