<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">{{readonly ? 'View' : 'Update'}} BayLock: {{modalInfo.WLWheelLockID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Controller ID - Controller Ref"
                    v-model="form.controller.selected"
                    @change="execFilter"
                    :items="form.controller.items"
                    item-text="name"
                    item-value="WLControllerID"
                    :rules="form.controller.rule"
                    :loading="form.controller.loader"
                    :disabled="form.controller.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="MB ID - MB Ref *"
                    v-model="form.mainboard.selected"
                    :items="form.mainboard.items"
                    item-text="name"
                    item-value="WLMainboardID"
                    :rules="form.mainboard.rule"
                    :loading="form.mainboard.loader"
                    :disabled="form.mainboard.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Operator.item"
                        label="Operator"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CarParkUserInputCode.item"
                        label="CP Code"
                        required
                        disabled
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    disabled
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.WLWheelLockID.item"
                        label="BL ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.WLWheelLockUUID"
                        label="Token"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Ref.item"
                        :rules="form.Ref.rule"
                        label="BL Ref. *"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Baylock Type *"
                    v-model="form.Type.item"
                    :items="form.Type.list"
                    item-text="text"
                    item-value="value"
                    :rules="form.Type.rule"
                    :loading="form.Type.loader"
                    :disabled="form.Type.disable"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.TokenRef.item"
                        label="Token Ref. *"
                        :disabled="readonly"
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Bay ID *"
                    v-model="form.bay.selected"
                    @change="execFilterBays"
                    :items="form.bay.items"
                    item-text="name"
                    item-value="BayID"
                    :rules="form.bay.rule"
                    :loading="form.bay.loader"
                    :disabled="form.bay.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CPPrefix.item"
                        :rules="form.CPPrefix.rule"
                        label="CP Prefix"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.BayNo.item"
                        :rules="form.BayNo.rule"
                        label="Bay Number"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CreateDate.item"
                        label="Created Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.UpdateDate.item"
                        label="Updated Date"
                        required
                        disabled
                    ></v-text-field>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.Enabled.item"
                    :label="`${form.Enabled.item == 1 ? 'Enabled' : 'Disabled'}`"
                    :disabled="readonly"
                    ></v-switch>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator', 'controller', 'mainboard'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                Ref: {item: "", rule: []},
                WLWheelLockID: {item: "", rule: []},
                CarParkUserInputCode: {item: "", rule: []},
                Operator: {item: "", rule: []},
                TokenRef: {item: "", rule: []},
                CPPrefix: {item: "", rule: []},
                BayNo: {item: "", rule: []},
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                controller: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                mainboard: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                bay: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                Remark: {item: "", rule: []},
                Enabled: {item: "", rule: []},
                CreateDate: {item: "", rule: []},
                UpdateDate: {item: "", rule: []},
                 Type:{item: 1, rule: [], list:[{text: 'Wired', value: 1}, {text: 'Wireless', value: 2}]}
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async getAvailableBays(item){
            this.modal_loader = true;
            this.form.bay.items = [];
            try{
                let response = await this.$store.dispatch("viewAllCarParkAvailableBayByCarParkID", {carparkid: this.form.carpark.selected});
                let dt = response.data.record;
                if (response.data.code === 'AP000'){
                    let arr = [];
                    arr.push({name: `Select`, BayID: ''});
                    arr.push({name: `None`, BayID: 0});
                    dt.forEach(itm => {
                        arr.push({
                            name: `${itm.BayID} - ${itm.CarParkBayNo}`,
                            CarParkBayPrefix: itm.CarParkBayPrefix,
                            CarParkID: itm.CarParkID,
                            CarParkBayNo: itm.CarParkBayNo,
                            BayID: itm.BayID
                        });
                    });

                    if (item.BayID === 0 || (this.form.carpark.selected !== item.CarParkID))
                    this.form.bay.items = [...arr];

                    else
                    this.form.bay.items = [{name: `${item.BayID} - ${item.CarParkBayNo}`, CarParkBayPrefix: item.CarParkBayPrefix, CarParkID: item.CarParkID, CarParkBayNo: item.CarParkBayNo, BayID: item.BayID}, ...arr];
                }
            }

            catch(err){
                console.log(err);
            }
            this.modal_loader = false;
        },

        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.bay.rule = [
                v => v !== '' || 'This is required',
            ];

            this.form.mainboard.rule = [
                v => !!v || 'This is required',
            ];

            this.form.Ref.rule = [
                v => !!v || 'This is required',
            ];

            /*
            this.form.TokenRef.rule = [
                v => !!v || 'This is required',
            ];
            */

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.form.bay.rule = [];
            this.form.mainboard.rule = [];
            this.form.Ref.rule = [];
            this.form.TokenRef.rule = [];

            let carpark = [];
            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.carpark.selected = this.modalInfo.CarParkID;

            this.form.bay.selected = "";
            await this.getAvailableBays(this.modalInfo);

            this.form.controller.items = [];
            this.form.controller.items.push({name: `Select`, WLControllerID: ''});
            for (const [index, item] of this.controller.entries()){
                this.form.controller.items.push({
                    name: `${item.WLControllerID} - ${item.WLControllerRef}`,
                    WLControllerID: item.WLControllerID,
                    OperatorID: item.OperatorID,
                    CarParkID: item.CarParkID
                });
            }
            this.form.controller.selected = this.modalInfo.WLControllerID;

            this.form.Ref.item = this.modalInfo.WLWheelLockRef;
            this.form.TokenRef.item = this.modalInfo.WLWheelLockToken;
            this.form.WLWheelLockID.item = this.modalInfo.WLWheelLockID;
            this.form.operator.selected = this.modalInfo.OperatorID;
            this.form.Operator.item = this.modalInfo.OperatorID;
            this.form.Remark.item = this.modalInfo.WLLockRemark;
            this.form.Enabled.item = this.modalInfo.WLLockEnabled;
            this.form.CarParkUserInputCode.item = this.modalInfo.CarParkUserInputCode;
            this.form.CPPrefix.item = this.modalInfo.CarParkBayPrefix;
            this.form.BayNo.item = this.modalInfo.CarParkBayNo;
            this.form.CreateDate.item = this.modalInfo.WLLockCreatedDateTime;
            this.form.UpdateDate.item = this.modalInfo.WLLockUpdatedDateTime;
            await this.execFilter();
            this.form.mainboard.selected = this.modalInfo.WLMainboardID;
            this.form.bay.selected = this.modalInfo.BayID;
            this.form.Type.item = this.modalInfo.Type;
        },

        async execFilter(){
            this.form.mainboard.items = [];
            this.form.mainboard.selected = "";

            let selectedfilters = {
                controller: this.form.controller.selected
            };

            let fil = Object.entries(selectedfilters).filter((item) => item[1] !== "");
            let selectedfilter = fil[fil.length - 1] || "";

            let type = '';
            if (selectedfilter[0] == 'controller') type = "WLControllerID";

            let mainboard = this.mainboard.filter(item => {
                return item[type] == selectedfilter[1];
            });

            this.form.mainboard.items.push({name: `Select`, WLMainboardID: ''});
            for (const [index, item] of mainboard.entries()){
                this.form.mainboard.items.push({
                    name: `${item.WLMainboardID} - ${item.WLMainboardRef}`,
                    WLMainboardID: item.WLMainboardID,
                    OperatorID: item.OperatorID,
                    CarParkID: item.CarParkID
                });
            }

            let cpdetails = this.controller.find(item => item.WLControllerID === this.form.controller.selected);
            this.form.Operator.item = `${cpdetails.OperatorID} - ${cpdetails.OperatorName}`;
            this.form.CarParkUserInputCode.item = cpdetails.CarParkUserInputCode;
            this.form.carpark.selected = cpdetails.CarParkID;

            this.form.bay.selected = "";
            await this.getAvailableBays(this.modalInfo);
        },

        execFilterBays(){
            let baydetails = this.form.bay.items.find(item => item.BayID === this.form.bay.selected);
            this.form.CPPrefix.item = baydetails.CarParkBayPrefix;
            this.form.BayNo.item = baydetails.CarParkBayNo;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            this.form.Ref.item = Number(this.form.Ref.item);
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>