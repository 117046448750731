<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create Remittance Finance
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container style="height: 450px"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator *"
                    v-model="form.operator.selected"
                    :items="form.operator.items"
                    @change="execFilter('operator')"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    hint='Select "Not Applicable" if SP Fees % apply to Operator instead of by car park.'
                    persistent-hint
                    hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Product Code *"
                    v-model="form.productcode.selected"
                    :items="form.productcode.items"
                    item-text="name"
                    item-value="ProductCode_Code"
                    :rules="form.productcode.rule"
                    :loading="form.productcode.loader"
                    hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.adminfee.item"
                        :rules="form.adminfee.rule"
                        label="SP Fee % *"
                        hint='Insert 0 if no value'
                        persistent-hint
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.operatorfee.item"
                        :rules="form.operatorfee.rule"
                        label="Operator Fee % *"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <dpicker :key="counter" :datetime="form.startdate" :mode="'date'" @date="dateValStartTime" :close="dialog_local" :place="true" label="Start Date *" :width="'58px'"></dpicker>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';
import dpicker from "../DatePickerComponent";
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly', 'operator', 'carpark', 'productcode'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                productcode: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                adminfee: {item: '', rule: []},
                operatorfee: {item: '', rule: []},
                startdate: {item: '', itemforapi: '', rule: []},
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
            },

            btn_loading: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false,
            counter: 0
        }
    },

    components: {alertbox, dpicker},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.productcode.rule = [];
            this.form.operator.rule = [];
            this.form.adminfee.rule = [];
            this.form.operatorfee.rule = [];
            this.form.startdate.rule = [];
        },

        async beforeCreateForm(flag){
            this.form.productcode.rule = [
                v => !!v || 'This is required',
            ];

            this.form.operator.rule = [
                v => !!v || 'This is required',
            ];

            this.form.carpark.rule = [
                v => !!v || 'This is required',
            ];

            this.form.adminfee.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.operatorfee.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.startdate.rule = [
                v => !!v || 'This is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.counter++;
            this.onRuleChange();

            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            let filteredoperator = this.operator.filter(item => item.OperatorID !== "");
            for (const [index, item] of filteredoperator.entries()){
                operator.push(item);
            }

            this.form.operator.items = operator;
            this.form.operator.selected = "";

            let carpark = [];
            //carpark.push({name: 'Select', CarParkID: ''});
            carpark.push({name: 'Not Applicable', CarParkID: 'null'});
            let filteredcarpark = this.carpark.filter(item => item.CarParkID !== "");
            for (const [index, item] of filteredcarpark.entries()){
                carpark.push(item);
            }

            this.form.carpark.items = carpark;
            this.form.carpark.selected = "null";

            let productcode = [];
            productcode.push({name: 'Select', ProductCode_Code: ''});
            let filteredproductcode = this.productcode.filter(item => item.ProductCode_Code !== "");
            for (const [index, item] of filteredproductcode.entries()){
                productcode.push(item);
            }

            this.form.productcode.items = productcode;
            this.form.productcode.selected = "";

            this.form.adminfee.item = "";
            this.form.operatorfee.item = "";
            this.form.startdate.itemforapi = '';
            this.form.startdate.item = "";
            this.counter++;
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        execFilter(typ){
            if (typ == 'operator'){
                this.form.carpark.items = [];
                this.form.carpark.selected = 'null';

                if (this.form.operator.selected){
                    let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);

                    //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    this.form.carpark.items.push({name: 'Not Applicable', CarParkID: 'null'});

                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    this.form.carpark.items.push({name: 'Not Applicable', CarParkID: 'null'});
                    let itm = this.carpark;
                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }
            }
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.form.startdate.itemforapi = moment(new Date(this.form.startdate.item).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
                    this.form.adminfee.item = this.form.adminfee.item / 100;
                    this.form.operatorfee.item = this.form.operatorfee.item / 100;
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        dateValStartTime(value){
            this.form.startdate.item = value;
        },
    }
}
</script>