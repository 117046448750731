<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>BInfinite Pay</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="DateCreated"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('update_ms_bpstatus')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'update')">{{props.item.loginMobileID}}</a>
                                </template>
                                <span>Update Status</span>
                                </v-tooltip>
                                <v-tooltip top v-else-if="validateAccess('viewdetails_ms_bp')">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view')">{{props.item.loginMobileID}}</a>
                                </template>
                                <span>View Status</span>
                                </v-tooltip>
                                <span v-else>{{props.item.loginMobileID}}</span>
                            </td>
                            <!-- <td>{{props.item.userID}}</td> -->
                            <td>{{props.item.mobile}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.mobileCreatedDateTime}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.lockStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.lockStatus, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpin :dialog="ma.modal_viewpin" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @closed="onClosed"></viewpin>
        <viewcarplate :dialog="ma.modal_viewcarplate" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @closed="onClosed"></viewcarplate>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <createtransfertokens :dialog="ma.modal_createentry" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></createtransfertokens>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateMobileSubscriberDriver';
import edit from '.././modals/UpdateMobileSubscriberBPayStatus';
import viewpin from '.././modals/ViewPIN';
import viewcarplate from '.././modals/ViewCarPlate';
import createtransfertokens from '.././modals/CreateTransferTokens.vue';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'BInfinite Pay',
    title: 'BInfinite Pay',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Subscriber',
                disabled: true,
                href: '#',
                },

                {
                text: 'Mobile',
                disabled: true,
                href: '#',
                },

                {
                text: 'BInfinite Pay',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*3. Mobile -> table -> #, Mobile ID, Name, Email, Mobile No., Link Email, Date Registered, Total Parking Tokens, Status, PIN */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Mobile ID', value: 'loginMobileID', width: '3%' },
                //{ text: 'User ID', value: 'userID', width: '13%' },
                { text: 'BPay User ID', value: 'name', width: '13%' },
                { text: 'Name', value: 'name', width: '13%' },
                { text: 'Date Registered', value: 'mobileCreatedDateTime', width: '13%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Status', value: 'lockStatus', width: '3%' }
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_viewpin: false,
                modal_viewcarplate: false,
                modal_createentry: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
        }
    },

    components: {create, edit, alert, alertbox2, viewpin, createtransfertokens, viewcarplate},

    async created(){
        await this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            this.items = [];

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getMobileSubscriber", {page: pg > 0 ? this.page = pg : this.page, search: this.search, registrationType: 'BPAY_QR' });

                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].mobileCreatedDateTime = moment(dt[i].mobileCreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].mobileVerifiedDate = dt[i].mobileVerifiedDate == null ? "N/A" : moment(dt[i].mobileVerifiedDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastLoginDate = dt[i].lastLoginDate == null ? "N/A" : moment(dt[i].lastLoginDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].emailVerifiedDate = dt[i].emailVerifiedDate == null ? "N/A" : moment(dt[i].emailVerifiedDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].PIN = "PIN";
                    dt[i].name = dt[i].name || "N/A";
                    dt[i].mobile = dt[i].mobile || "N/A";
                    dt[i].email = dt[i].email == null || dt[i].email == "" ? "N/A" : dt[i].email;
                    dt[i].linkedemail = dt[i].linkedemail == null || dt[i].linkedemail == "" ? "N/A" : dt[i].linkedemail;
                    dt[i].loginEmailID = dt[i].loginEmailID == null ? "N/A" : dt[i].loginEmailID;
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async statusUpdate(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateMobileSubscriberStatus", {loginmobileid: this.ma.modalInfo.loginMobileID, lockaccountstatus: v.lockStatus})
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Status has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async addMobileSubscriber(v){
            console.log(v.customermobileno.item);
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {customermobileno: v.customermobileno.item};
                let response = await this.$store.dispatch("createMobileSubscriberDriver", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Subscriber Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This subscriber is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async transferToken(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    senderLoginMobileID: v.frommobileid.item,
                    receiverLoginMobileID: v.tomobileid.item,
                    paymentTokenAmt: v.tokensamount.item,
                };
                let response = await this.$store.dispatch("createTransferTokens", req);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Token Transferred!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pin'){
                this.ma.modal_viewpin = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'view_carplate'){
                this.ma.modal_viewcarplate = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'createentry'){
                this.ma.modal_createentry = true;
                this.ma.modalInfo = row;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_createentry = value;
                this.ex.modal = value;
                this.ma.modal_viewpin = value;
                this.ma.modal_viewcarplate = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addMobileSubscriber(value);
            }

            if (value.flag === 'create_transfertokens'){
                this.ma.confirmCreate = true;
                this.ma.modal_createentry = false;
                this.transferToken(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.statusUpdate(value);
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 0){ return 'Enable'}
                    else if (val === 1){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 0){ return 'green'}
                    else if (val === 1){return 'red'}
                    else {return 'grey'}
            }
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }
    }
}
</script>