<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Parking Tokens Menu</v-card-title>
        <v-container class="fill-height"
        fluid>
            <!--
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Systems"
                    label="System"
                    v-model="system.selected"
                    @change="execFilter('system')"
                    :items="system.items"
                    item-text="name"
                    item-value="SystemID"
                    :rules="system.rule"
                    :loading="system.loader"
                    :disabled="system.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            -->
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('ptm_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Menu
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? page * 100 : 1}} - {{page > 1 ? items.length + 100 : items.length}} of {{items.length}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        sort-by="CreatedDateTime"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('ptm_update')">
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.ParkingTokenBundleID}}</a>
                                </template>
                                <span>Update</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.ParkingTokenBundleID}}</a>
                                </template>
                                <span>View</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.TokenAmount}}</td>
                            <td>{{props.item.TokenPriceFinalTotal}}</td>
                            <td>{{props.item.ServiceFeePercentage}}</td>
                            <td>{{props.item.ServiceFeeFinalTotal}}</td>
                            <td>{{props.item.GrandFinalTotal}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.UpdatedDateTime}}</td>
                            <td>{{props.item.ActionByAdminID_Name}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="mobileDisplayStatus(props.item.MobileDisplayStatus, 'color')"
                                text-color="white">
                                    {{mobileDisplayStatus(props.item.MobileDisplayStatus, 'text')}}
                                </v-chip>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.ActiveStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.ActiveStatus, 'text')}}
                                </v-chip>
                            </td>
                            <!--
                            <td v-if="validateAccess('operator_delete')">
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" id="custom-hover" @click.prevent="viewMore(props.item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                                </template>
                                <span>Remove Operator</span>
                                </v-tooltip>
                            </td>
                            -->
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateParkingTokenMenu';
import edit from '.././modals/UpdateParkingTokenMenu';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Parking Tokens Menu',
    title: 'Parking Tokens Menu',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },

                {
                text: 'Parking Tokens',
                disabled: true,
                href: '#',
                },

                {
                text: 'Parking Tokens Menu',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'ParkingTokenBundleID', width: '3%' },
                { text: 'Parking Tokens', value: 'TokenAmount', width: '5%' },
                { text: 'Cost (RM)', value: 'TokenPriceFinalTotal', width: '7%' },
                { text: 'Service Fees (%)', value: 'ServiceFeePercentage', width: '7%' },
                { text: 'Service Fees (RM)', value: 'ServiceFeeFinalTotal', width: '7%' },
                { text: 'Total Cost (RM)', value: 'GrandFinalTotal', width: '7%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'UpdatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Last Update Person', value: 'ActionByAdminID_Name', width: '5%' },
                { text: 'Display on Mobile', value: 'MobileDisplayStatus', width: '5%' },
                { text: 'Status', value: 'ActiveStatus', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: []
        }
    },

    components: {create, edit, alert, alertbox2},

    async created(){
        this.listAll();
    },

    methods: {
        async listAll(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getAllParkingTokens", {});

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count || dt.length;
                const events = [];

                dt.forEach((doc, i) => {
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.TokenAmount = doc.TokenAmount;
                    appData.TokenPriceFinalTotal = doc.TokenPriceFinalTotal;
                    appData.ServiceFeeFinalTotal = doc.ServiceFeeFinalTotal;
                    //appData.ServiceFeeFinalTotalPercent = ((doc.ServiceFeeFinalTotal / doc.TokenPriceFinalTotal) * 100).toFixed(2);
                    appData.GrandFinalTotal = doc.GrandFinalTotal;
                    appData.CreatedDateTime = doc.CreatedDateTime == null ? "N/A" : moment(new Date(doc.CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    appData.UpdatedDateTime = doc.UpdatedDateTime == null ? "N/A" : moment(new Date(doc.UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    events.push(appData);
                })
                /*
                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    //replace(/\s/g,'') remove any literal whitespace
                }

                let self = this;
                if (this.system.selected){
                    let items = dt.filter(item => {
                    return item.SystemID == self.system.selected;
                    });

                    dt = items;
                }
                */

                this.items = events;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addOperator(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    parkingtokenamount: Number(v.parkingtokens.item),
                    parkingtokenpricecost: Number(v.unitprice.item),
                    activestatus: v.status.item,
                    mobiledisplaystatus: v.displayonmobile.item
                };
                let response = await this.$store.dispatch("createParkingToken", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listAll();
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Parking Token Created!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async operatorRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteParkingToken", {parkingtokenbundleid: this.ma.modalInfo.ParkingTokenBundleID})
                if (response.data.code === 'AP000'){
                        await this.listAll();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Parking Token has been removed!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async operatorUpdate(v){
            console.log('here', v.parkingtokens.item);
            let req = {
                parkingtokenbundleid: this.ma.modalInfo.ParkingTokenBundleID,
                parkingtokenamount: Number(v.parkingtokens.item),
                parkingtokenpricecost: Number(v.unitprice.item),
                activestatus: v.status.item,
                mobiledisplaystatus: v.displayonmobile.item
            };

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateParkingToken", req)
                if (response.data.code === 'AP000'){
                        await this.listAll();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Parking Token has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log('aa', value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addOperator(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.operatorUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.operatorRemove();
                }
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.operatorRemove();
        },

        async execFilter(type){
            if (type == 'system'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAll();
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enabled'}
                    else if (val === 0){return 'Disabled'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        mobileDisplayStatus(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Yes'}
                    else if (val === 0){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },
    }
}
</script>