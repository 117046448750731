<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">{{readonly ? 'View' : 'Update'}} Gate Controller: {{modalInfo.GateID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Controller"
                    label="Controller"
                    v-model="form.GateControllerID.selected"
                    @change="filterController"
                    :items="form.GateControllerID.items"
                    item-text="name"
                    item-value="GateControllerID"
                    :rules="form.GateControllerID.rule"
                    :loading="form.GateControllerID.loader"
                    :disabled="form.GateControllerID.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Operator.item"
                        label="Operator"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CarParkUserInputCode.item"
                        label="CP Code"
                        required
                        disabled
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.GateID.item"
                        label="Gate ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Type"
                    v-model="form.GateType.selected"
                    :items="form.GateType.items"
                    item-text="name"
                    item-value="ID"
                    :rules="form.GateType.rule"
                    :loading="form.GateType.loader"
                    :disabled="form.GateType.disable || readonly"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.PoleDesc.item"
                        :rules="form.PoleDesc.rule"
                        label="Pole No."
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastSeenDate2"
                        label="Date Last Seen"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastOpenDate"
                        label="Date Last Open"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastPinDate"
                        label="Date Last PIN"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastConnectDate"
                        label="Date Last Connected"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastDisconnectDate"
                        label="Date Last Disonnected"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastPresentDate"
                        label="Date Last Present"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.lastAbsentDate"
                        label="Date Last Absent"
                        required
                        disabled
                    ></v-text-field>
                    -->
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="form.CreateDate.item"
                        label="Created Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.UpdateDate.item"
                        label="Updated Date"
                        required
                        disabled
                    ></v-text-field>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.Enabled.item"
                    :label="`${form.Enabled.item == 1 ? 'Enabled' : 'Disabled'}`"
                    :disabled="readonly"
                    ></v-switch>
                    -->
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                GateToken: {item: ""},
                GateID: {item: ""},
                GateUUID: {item: ""},
                RefGateID: {item: ""},
                TokenRef: {item: ""}, //wait backend
                CarParkUserInputCode: {item: ""},
                GateControllerID: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                Operator: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: true,
                    rule: []
                },
                GateType: {items: [{ID: '', name: "Select"}, {ID: 'A', name: "A"}, {ID: 'B', name: "B"}, {ID: 'C', name: "C"}, {ID: 'D', name: "D"}], selected: "", rule: []},
                PoleDesc: {item: "", rule: []},
                Remark: {item: "", rule: []},
                IsEntryGate: {items: [{ID: 1, name: "Entry"}, {ID: 0, name: "Exit"}], selected: "", rule: []},
                Enabled: {item: "", rule: []},
                CreateDate: {item: "", rule: []},
                UpdateDate: {item: "", rule: []},
                GateCareToken: {item: "", rule: []}
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async getControllerFull(){
            let list = [];
            this.form.GateControllerID.items = [];
            this.form.GateControllerID.selected = "";

            try{
                this.form.GateControllerID.loader = true;
                this.form.GateControllerID.disable = true;
                this.modal_loader = true;

                let self = this;
                let response = await this.$store.dispatch("getGateController", {search: '', rowresultsperpage: null});
                let response2 = await this.$store.dispatch("getAllCarpark2", {search: ''});
                let dt = response.data.record;
                let dt2 = response2.data.record;
                if (response.data.code == 'AP000'){
                    this.form.GateControllerID.items.push({name: "Select Controller", GateControllerID: ""});
                    this.form.GateControllerID.itemsFull.push({name: "Select Controller", GateControllerID: ""});

                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].GateControllerID} - ${dt[i].RefControllerID}`;
                    let carpark = dt2.find(item => item.CarParkID == dt[i].CarParkID);
                    dt[i].CarParkUserInputCode = carpark.CarParkUserInputCode;
                    this.form.GateControllerID.items.push({name: dt[i].name, GateControllerID: dt[i].GateControllerID, OperatorID: dt[i].OperatorID, CarParkID: dt[i].CarParkID, CarParkUserInputCode: dt[i].CarParkUserInputCode});
                    this.form.GateControllerID.itemsFull.push({name: dt[i].name, GateControllerID: dt[i].GateControllerID, OperatorID: dt[i].OperatorID, CarParkID: dt[i].CarParkID, CarParkUserInputCode: dt[i].CarParkUserInputCode});
                }
                }

                this.form.GateControllerID.loader = false;
                this.form.GateControllerID.disable = false;
                this.modal_loader = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.GateControllerID.rule = [
                v => !!v || 'This is required',
            ];

            this.form.GateType.rule = [
                v => !!v || 'This is required',
            ];

            this.form.PoleDesc.rule = [
                v => !!v || 'This is required',
            ];

            this.form.RefGateID.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        filterController(){
            if (this.form.GateControllerID.selected != ""){
                let controller = this.form.GateControllerID.items.find(item => item.GateControllerID == this.form.GateControllerID.selected);
                this.form.Operator.item = controller.OperatorID;
                this.form.carpark.selected = controller.CarParkID;
                this.form.CarParkUserInputCode.item = controller.CarParkUserInputCode;
            }

            else{
                this.form.Operator.item = this.modalInfo.OperatorID;
                this.form.carpark.selected = this.modalInfo.CarParkID;
                this.form.CarParkUserInputCode.item = this.modalInfo.CarParkUserInputCode;
            }
        },

        async handleValue(){
            await this.getControllerFull();
            this.form.GateControllerID.rule = [];
            this.form.GateType.rule = [];
            this.form.PoleDesc.rule = [];
            this.form.Remark.rule = [];
            this.form.IsEntryGate.rule = [];
            this.form.Enabled.rule = [];
            this.form.RefGateID.rule = [];

            this.form.carpark.selected = "";
            this.form.IsEntryGate.selected = "";
            //let filteredcarparkfromoperatorid = this.carpark.filter(item => item.OperatorID == this.modalInfo.OperatorID);
            let carpark = [];
            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.carpark.selected = this.modalInfo.CarParkID;

            this.form.GateToken.item = this.modalInfo.GateToken;
            this.form.GateUUID.item = this.modalInfo.GateUUID;
            this.form.GateID.item = this.modalInfo.GateID;
            this.form.RefGateID.item = this.modalInfo.RefGateID;
            this.form.TokenRef.item = "N/A";
            this.form.GateControllerID.selected = this.modalInfo.GateControllerID;
            this.form.IsEntryGate.selected = this.modalInfo.IsEntryGate;
            this.form.Operator.item = this.modalInfo.OperatorID;
            this.form.GateType.selected = this.modalInfo.GateType == null ? "" : this.modalInfo.GateType;
            this.form.PoleDesc.item = this.modalInfo.PoleDesc;
            this.form.Remark.item = this.modalInfo.Remark;
            this.form.Enabled.item = this.modalInfo.Enabled;
            this.form.CreateDate.item = this.modalInfo.CreateDate;
            this.form.UpdateDate.item = this.modalInfo.UpdateDate;
            this.form.CarParkUserInputCode.item = this.modalInfo.CarParkUserInputCode;
            this.form.GateCareToken.item = this.modalInfo.GateCareToken;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            this.form.GateToken.item = this.form.GateToken.item == null ? "" : this.form.GateToken.item;
            this.form.Remark.item = this.form.Remark.item == null ? "" : this.form.Remark.item;
            this.form.PoleDesc.item = this.form.PoleDesc.item == null ? "" : this.form.PoleDesc.item;

                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>