<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Product Services Fees</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <!--
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                -->
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Type"
                    label="Type"
                    v-model="bywhat.selected"
                    @change="execFilter('type')"
                    :items="bywhat.items"
                    item-text="name"
                    item-value="id"
                    :rules="bywhat.rule"
                    :loading="bywhat.loader"
                    :disabled="bywhat.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('orf_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Remittance Finance
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            -->
            <v-row dense>
                <!-- <v-col><span class="text-body-2">View {{page > 1 ? page * 100 : 1}} - {{page > 1 ? items.length + 100 : items.length}} of {{items.length}}</span></v-col> -->
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.SystemName}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.OperatorName}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td style="white-space: pre">{{props.item.CarParkName}}</td>
                            <td>{{props.item.ProductCode}}</td>
                            <td>{{props.item.ProductCode_Name}}</td>
                            <td>{{props.item.FeePercentage}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.ActiveStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.ActiveStatus, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :productcode="productcode.itemsFull" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateOperatorRemittance';
import edit from '.././modals/UpdateOperatorRemittanceFinance';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Product Services Fees',
    title: 'Product Services Fees',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },

                {
                text: 'Setting',
                disabled: true,
                href: '#',
                },

                {
                text: 'Product Services Fees',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'System', value: 'SystemName', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'Operator', value: 'OperatorName', width: '10%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '10%' },
                { text: 'Product Code', value: 'ProductCode', width: '3%' },
                { text: 'Product', value: 'ProductCode_Name', width: '7%' },
                { text: 'Service Fees %', value: 'SPFeePercentage', width: '3%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Status', value: 'ActiveStatus', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            productcode: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            bywhat: {
                items: [
                    {id: '', name: 'Select Type'},
                    {id: 'SYSTEM', name: 'System'},
                    {id: 'OPERATOR', name: 'Operator'},
                    {id: 'CARPARK', name: 'Car Park'},
                ],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: []
        }
    },

    components: {create, edit, alert, alertbox2},

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listAll();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    async created(){
        await this.getSystemFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getProductCodeFull();
        await this.listAll(1);
    },

    methods: {
        async listAll(pg){
            try{
                this.tableloading = true;
                if (this.bywhat.selected.length > 0){
                    let response = await this.$store.dispatch("getProductServicesFees", {bywhat: this.bywhat.selected, productcode: 'ALL'});

                    this.services = response;
                    if (response.data.code == 'AP000'){
                        let dt = response.data.record;
                        const events = [];

                        Number.prototype.percent = function() {
                            // Round number up: Math.ceil
                            // Round number: Math.round
                            // Round number down: Math.floor
                            return +(this * 100).toFixed(2);
                        }

                        dt.forEach((doc, i) => {
                            let appData = doc;
                            appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                            appData.CreatedDateTime = doc.CreatedDateTime == null ? "N/A" : moment(new Date(doc.CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                            appData.SystemName = appData.SystemID == null ? "N/A" : this.system.itemsFull.find(itm => itm.SystemID == appData.SystemID).name;
                            appData.SystemID = appData.SystemID == null ? "N/A" : appData.SystemID;
                            appData.OperatorName = appData.OperatorID == null ? "N/A" : this.operator.itemsFull.find(itm => itm.OperatorID == appData.OperatorID).name;
                            appData.OperatorID = appData.OperatorID == null ? "N/A" : appData.OperatorID;
                            appData.CarParkName = appData.CarParkID == null ? "N/A" : this.carpark.itemsFull.find(itm => itm.CarParkID == appData.CarParkID).name;
                            appData.CarParkID = appData.CarParkID == null ? "N/A" : appData.CarParkID;
                            if (appData.CarParkID !== "N/A"){
                                appData.SystemID = this.carpark.itemsFull.find(itm => itm.CarParkID == appData.CarParkID).SystemID;
                                appData.SystemName = this.carpark.itemsFull.find(itm => itm.CarParkID == appData.CarParkID).SystemName;
                                appData.OperatorID = this.carpark.itemsFull.find(itm => itm.CarParkID == appData.CarParkID).OperatorID;
                                appData.OperatorName = this.carpark.itemsFull.find(itm => itm.CarParkID == appData.CarParkID).OperatorName;
                            }

                            if (appData.OperatorID !== "N/A"){
                                appData.SystemName = this.operator.itemsFull.find(itm => itm.OperatorID == appData.OperatorID).SystemName;
                            }

                            appData.ProductCode_Name = this.productcode.items.find(itm => itm.ProductCode_Code == appData.ProductCode).name;
                            appData.FeePercentage = (appData.FeePercentage).percent();
                            
                            events.push(appData);
                        })

                        this.items = events;
                    }
                }

                else{
                    this.items = [];
                }
                
                this.tableloading = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async getSystemFull(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.system.items.push({name: "Select System", SystemID: ""});
                    this.system.itemsFull.push({name: "Select System", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                //this.system.selected = this.system.items[0].SystemID;

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorName}`;
                        dt[i].SystemName = this.system.itemsFull.find(itm => itm.SystemID === dt[i].SystemID).name;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID, SystemName: dt[i].SystemName});
                        this.operator.itemsFull.push({name: dt[i].name, OperatorID: dt[i].OperatorID, SystemName: dt[i].SystemName});
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkName}`;
                        carpark[i].OperatorName = this.operator.itemsFull.find(itm => itm.OperatorID === carpark[i].OperatorID).name;
                        carpark[i].SystemName = this.system.itemsFull.find(itm => itm.SystemID === carpark[i].SystemID).name;
                        this.carpark.items.push({
                            name: carpark[i].name,
                            CarParkID: carpark[i].CarParkID,
                            OperatorID: carpark[i].OperatorID,
                            OperatorName: carpark[i].OperatorName,
                            SystemID: carpark[i].SystemID,
                            SystemName: carpark[i].SystemName
                        });
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                            dt[i].name = `${dt[i].CarParkName}`;
                            dt[i].OperatorName = this.operator.itemsFull.find(itm => itm.OperatorID === dt[i].OperatorID).name;
                            dt[i].SystemName = this.system.itemsFull.find(itm => itm.SystemID === dt[i].SystemID).name;
                            this.carpark.items.push({
                                name: dt[i].name,
                                CarParkID: dt[i].CarParkID,
                                OperatorID: dt[i].OperatorID,
                                OperatorName: dt[i].OperatorName,
                                SystemID: dt[i].SystemID,
                                SystemName: dt[i].SystemName
                            });
                            this.carpark.itemsFull.push({
                                name: dt[i].name,
                                CarParkID: dt[i].CarParkID,
                                OperatorID: dt[i].OperatorID,
                                OperatorName: dt[i].OperatorName,
                                SystemID: dt[i].SystemID,
                                SystemName: dt[i].SystemName
                            });
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getProductCodeFull(){
            let list = [];
            this.productcode.items = [];
            this.productcode.selected = "";

            try{
                this.productcode.loader = true;
                this.productcode.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getAllProductCode", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.productcode.items.push({name: "All Operators", ProductCode_Code: ""});
                    this.productcode.itemsFull.push({name: "All Operators", ProductCode_Code: ""});

                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].ProductCode_Name}`;
                    this.productcode.items.push({name: dt[i].name, ProductCode_Code: dt[i].ProductCode_Code});
                    this.productcode.itemsFull.push(dt[i]);
                }

                this.productcode.selected = this.productcode.items[0].ProductCode_Code;
                }

                //this.operator.itemsFull = dt;

                this.productcode.loader = false;
                this.productcode.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addOperator(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    carparkid: v.carpark.selected,
                    operatorid: v.operator.selected,
                    productcode: v.productcode.selected,
                    spfeepercentage: v.spfeepercentage.item,
                    feestartdate: v.startdate.itemforapi
                };
                let response = await this.$store.dispatch("createSPFee", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listAll(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Remittance Finance Created!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async operatorRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteSPFee", {spfeeid: this.ma.modalInfo.ID})
                if (response.data.code === 'AP000'){
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Remittance Finance has been removed!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async operatorUpdate(v){
            console.log('here', v);
            let req = {
                spfeeid: this.ma.modalInfo.ID,
                carparkid: v.carpark.selected,
                operatorid: v.operator.selected,
                productcode: v.productcode.selected,
                spfeepercentage: v.spfeepercentage.item,
                feestartdate: v.startdate.itemforapi
            };

            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateSPFee", req)
                if (response.data.code === 'AP000'){
                        this.items = [];
                        await this.listAll(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Remittance Finance has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log('aa', value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addOperator(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.operatorUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.operatorRemove();
                }
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.operatorRemove();
        },

        async execFilter(type){
            if (type == 'type'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAll(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enabled'}
                    else if (val === 0){return 'Disabled'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        mobileDisplayStatus(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Yes'}
                    else if (val === 0){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },
    }
}
</script>