<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Details: {{modalInfo.TrxRef}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay
                :absolute="true"
                :opacity="1"
                :z-index="999"
                :value="modal_loader"
                color="#D2D7DF"
                >
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-overlay>
            <v-row v-if="items.length > 0">
                <v-col cols="12" md="12">
                    <!--
                        Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description
                    -->
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].TrxRef"
                        label="Ref. No."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.loginMobileID"
                        label="Mobile ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].forname"
                        label="Name"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].formobile"
                        label="Mobile No."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].foremail"
                        label="Profile Email"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].linkedemail"
                        label="Link Email"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].TransactionDateTime"
                        label="Transaction Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ProductCode"
                        label="Product"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Action"
                        label="Description"
                        required
                        disabled
                    ></v-text-field>
                    <span v-if="modalInfo.frommobile == null">
                        <v-text-field
                            v-if="checkExist(items[0].Action, 'Received From')"
                            dense
                            outlined
                            v-model="items[0].ActionByAdmin"
                            label="Received From"
                            required
                            disabled
                        ></v-text-field>
                    </span>
                    <span v-if="modalInfo.frommobile !== null">
                        <v-text-field
                            v-if="checkExist(items[0].Action, 'Received From')"
                            dense
                            outlined
                            v-model="modalInfo.frommobile"
                            label="Received From"
                            required
                            disabled
                        ></v-text-field>
                    </span>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Car Park')"
                        dense
                        outlined
                        v-model="items[0].CarParkName"
                        label="Car Park"
                        required
                        disabled
                    ></v-text-field>
                    <span v-if="items[0].Action == 'Send SOS Message'">
                        <v-text-field
                            v-if="checkExist(items[0].Action, 'Sent To')"
                            dense
                            outlined
                            v-model="items[0].SOSReceiverMobileNo"
                            label="Sent To"
                            required
                            disabled
                        ></v-text-field>
                    </span>
                    <span v-else>
                        <v-text-field
                            v-if="checkExist(items[0].Action, 'Sent To')"
                            dense
                            outlined
                            v-model="items[0].tomobile"
                            label="Sent To"
                            required
                            disabled
                        ></v-text-field>
                    </span>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'City')"
                        dense
                        outlined
                        v-model="items[0].CarParkAddressCity_Name"
                        label="City"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Value Pass')"
                        dense
                        outlined
                        v-model="items[0].ValuePassName"
                        label="Value Pass"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Quantity')"
                        dense
                        outlined
                        v-model="items[0].ValuePassQuantity"
                        label="Quantity"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Currency')"
                        dense
                        outlined
                        v-model="items[0].ItemCurrency"
                        label="Currency"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Total Amount')"
                        dense
                        outlined
                        v-model="items[0].ItemFinalAmount"
                        label="Total Amount"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Payment Method')"
                        dense
                        outlined
                        v-model="items[0].PaymentMethod"
                        label="Payment Method"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        v-if="checkExist(items[0].Action, 'Transaction ID')"
                        dense
                        outlined
                        v-model="items[0].PaymentProvider3rdPartyRefNo"
                        label="Transaction ID"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="12">
                    <span class="text-body-2">No entry found for this parking entry</span>
                </v-col>
            </v-row>
              <!--
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ParkingEntryID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.CurrentCustomerMobile}}</td>
                            <td>{{props.item.ParkingEntryDateTime}}</td>
                            <td>{{props.item.Duration}}</td>
                            <td>{{props.item.ExitMethod}}</td>
                            <td>{{props.item.ValuePassID}}</td>
                            <td>{{props.item.ValuePassUnitID}}</td>
                            <td>{{props.item.ValuePassName}}</td>
                            <td>{{props.item.TotalTokens}}</td>
                            <td>{{props.item.Amount_CurrentFees}}</td>
                            <td>{{props.item.Amount_Due}}</td>
                            <td>{{props.item.Amount_FutureFees}}</td>
                        </tr>
                    </template>
            </v-data-table>
            -->
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'Parking ID', value: 'ParkingEntryID' },
                { text: 'CP ID', value: 'CarParkID' },
                { text: 'Car Park', value: 'CarParkName' },
                { text: 'Mobile No.', value: 'CurrentCustomerMobile'},
                { text: 'Date Of Entry', value: 'ParkingEntryDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Parking Duration (dd:hh:mm)', value: 'Duration' },
                { text: 'Exit Method', value: 'ExitMethod' },
                { text: 'VP ID', value: 'ValuePassID' }, //need to update to actual param
                { text: 'VP Unit ID', value: 'ValuePassUnitID' }, //need to update to actual param
                { text: 'Value Pass Name', value: 'ValuePassName' },
                { text: 'Total Parking Tokens', value: 'TotalTokens' }, //need to update to actual param
                { text: 'Current Fees (RM)', value: 'Amount_CurrentFees' },
                { text: 'Pending Paid (RM)', value: 'Amount_Due' },
                { text: 'Next 2 Hours Fees (RM)', value: 'Amount_FutureFees' }, //need to update to actual param
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1,
            modal_loader: true,
            amount_due_color: ''
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];
            this.tableloading = true;
            this.modal_loader = true;

            let response = await this.$store.dispatch('getCustomerParkingHistoryByTrxRef', {trxref: this.modalInfo.TrxRef, loginmobileid: this.modalInfo.loginMobileID})
            if (response.data.code == "AP000"){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    /*
                    if Action = Casual Parking / Season Parking -> display fields ->               Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Car Park, City, Currency, Total Amount, Payment Method, Transaction ID
                    if Action = Purchase Parking Token -> display fields ->                        Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Currency, Total Amount, Payment Method, Transaction ID
                    if Action = Received Parking Tokens / Received Value Pass -> display fields -> Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Received From, Currency, Total Amount, Payment Method, Transaction ID
                    if Action = Sent Parking Tokens / Sent Value Pass -> display fields ->         Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Sent To, Currency, Total Amount, Payment Method, Transaction ID
                    if Action = Purchase Value Pass -> display fields ->                           Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Car Park, City, Value Pass,  Quantity, Currency, Total Amount, Payment Method, Transaction ID
                    if Action = Send SOS Message -> display fields ->                              Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Sent To, Currency, Total Amount, Payment Method, Transaction ID
                    */
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].TransactionDateTime = moment(dt[i].TransactionDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].fromname = dt[i].fromname == null ? "N/A" : dt[i].fromname;
                    dt[i].toname = dt[i].toname == null ? "N/A" : dt[i].toname;
                    dt[i].tomobile = dt[i].tomobile == null ? "N/A" : dt[i].tomobile;
                    dt[i].TicketID = dt[i].TicketID == null ? "N/A" : dt[i].TicketID;
                    dt[i].CarParkName = dt[i].CarParkName == null ? "N/A" : dt[i].CarParkName;
                    dt[i].CarParkAddressCity_Name = dt[i].CarParkAddressCity_Name == null ? "N/A" : dt[i].CarParkAddressCity_Name;

                    dt[i].PaymentProvider3rdPartyRefNo = dt[i].PaymentProvider3rdPartyRefNo == null ? "N/A" : dt[i].PaymentProvider3rdPartyRefNo;

                    dt[i].forname = dt[i].forname == null ? "N/A" : dt[i].forname;
                    dt[i].formobile = dt[i].formobile == null ? "N/A" : dt[i].formobile;
                    dt[i].foremail = dt[i].foremail == null ? "N/A" : dt[i].foremail;
                    dt[i].linkedemail = dt[i].linkedemail == null ? "N/A" : dt[i].linkedemail;
                    dt[i].ItemFinalAmount = dt[i].ItemCurrency == "Parking Token" ? Number(dt[i].ItemFinalAmount) : Number(dt[i].ItemFinalAmount).toFixed(2);

                    dt[i].ValuePassName = dt[i].ValuePassName == null ? "N/A" : dt[i].ValuePassName;
                    dt[i].ValuePassQuantity = dt[i].ValuePassQuantity == null ? "N/A" : dt[i].ValuePassQuantity;
                }

                this.items = dt;
            }

            this.tableloading = false;
            this.modal_loader = false;
        },

        checkExist(action, name){
            let items = [
                ['Casual Parking', 'Season Parking'],
                ['Purchase Parking Token'],
                ['Received Parking Token', 'Received Value Pass'],
                ['Sent Parking Token', 'Sent Value Pass'],
                ['Purchase Value Pass'],
                ['Send SOS Message']
            ]

            let values = [
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Car Park, City, Currency, Total Amount, Payment Method, Transaction ID",
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Currency, Total Amount, Payment Method, Transaction ID",
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Received From, Currency, Total Amount, Payment Method, Transaction ID",
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Sent To, Currency, Total Amount, Payment Method, Transaction ID",
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Car Park, City, Value Pass, Quantity, Currency, Total Amount, Payment Method, Transaction ID",
                "Ref. No., Mobile ID, Name, Mobile No., Email, Transaction Date, Product, Description, Sent To, Currency, Total Amount, Payment Method, Transaction ID"
            ];

           let truth = false;
           for(let i=0; i<=5; i++){
               if (items[i].indexOf(action) != -1){
                    let valuesnew = values[i];
                    let valuesarr = valuesnew.split(', ');
                    truth = valuesarr.indexOf(name) != -1;
                }
           }

           return truth;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>