<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Gated Parking</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
             <v-row>
                <v-col cols="12" md="12">
                    <vc-date-picker
                    :disabled-dates='dayplusone'
                     :key="counter1" v-model="starttime.item" mode="dateTime" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="listItems(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="From Date - To Date"
                                outlined
                                autocomplete="off"
                                persistent-hint
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('create_parkingentry')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" class="mr-2" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Manual Exit
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                    <v-btn v-if="validateAccess('create_parkingentryentry')" :loading="btn_loadingentry" :disabled="btn_loadingentry" tile type="submit" small color="green" style="color: white" @click="viewMore(1, 'createentry')"><v-icon left x-small dark>fa fa-cogs</v-icon>Manual Entry
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="DateCreated"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ParkingEntryID}}</td>
                            <td>{{props.item.TicketID}}</td>
                            <td>{{props.item.OperatorID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre;">{{props.item.CarParkName}}</td>
                            <td>{{props.item.loginMobileID}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.mobile}}</td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.CarPlateID}}</td>
                            <td>
                                <v-tooltip top v-if="props.item.CarPlateNo !== null && props.item.CarPlateNo != 'N/A'">
                                <template v-slot:activator="{ on }">
                                <a  v-if="validateAccess('viewdetails_parkingentry')" style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_carplateno')">{{props.item.CarPlateNo}}</a>
                                <span v-else>{{props.item.CarPlateNo}}</span>
                                </template>
                                <span>Car Image</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                            </td>
                            <td>{{props.item.ParkingEntryDateTime}}</td>
                            <td>
                                <v-tooltip top v-if="props.item.Amount_Paid !== null">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_payment')">{{props.item.Amount_Paid}}</a>
                                </template>
                                <span>Payment Details</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                            </td>
                            <td>{{props.item.LastPaidTimeDate}}</td>
                            <td v-if="validateAccess('viewdetails_parkingentry')">
                                <v-tooltip top v-if="props.item.ExitMethod !== null">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_paymentdue')">Exit Method</a>
                                </template>
                                <span>Payment Due Details</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <viewpayment :dialog="ma.modal_viewpayment" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewpayment>
        <viewpaymentdue :dialog="ma.modal_viewpaymentdue" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewpaymentdue>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :category="ma.category" :issue="ma.issue" @confirm="onConfirm" @closed="onClosed"></create>
        <createentry :dialog="ma.modal_createentry" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :carpark="carpark.itemsFull" @confirm="onConfirm" @closed="onClosed"></createentry>
        <viewcarplate :dialog="ma.modal_view_carplateno" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo"  @closed="onClosed"></viewcarplate>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateManualExit';
import createentry from '.././modals/CreateManualEntry';
import edit from '.././modals/UpdateSubscriberSeasonStatus';
import viewpayment from '.././modals/ViewPaymentTrxn';
import viewpaymentdue from '.././modals/ViewPaymentDue';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';
import viewcarplate from '.././modals/ViewLprCarplate';
export default {
    name: 'Gated Parking',
    title: 'Gated Parking',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Parking Entry',
                disabled: true,
                href: '#',
                },

                {
                text: 'Gated Parking',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Entry ID', value: 'ParkingEntryID', width: '3%' },
                { text: 'Ticket ID', value: 'TicketID', width: '3%' },
                { text: 'CPO ID', value: 'OperatorID', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '5%' },
                { text: 'Mobile ID', value: 'loginMobileID', width: '5%' },
                { text: 'Name', value: 'name', width: '5%' },
                { text: 'Mobile No.', value: 'mobile', width: '5%' },
                { text: 'Profile Email', value: 'email', width: '5%' },
                { text: 'Car Plate ID', value: 'email', width: '5%' },
                { text: 'Car Plate', value: 'email', width: '5%' },
                { text: 'Date Of Entry', value: 'ParkingEntryDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Paid (RM)', value: 'Amount_Paid', width: '5%' },
                { text: 'Last Paid Date', value: 'LastPaidTimeDate', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Exit Method', value: 'ExitMethod', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            btn_loadingentry: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_createentry: false,
                readonly: true,
                allInfo: [],
                category: [],
                issue: [],
                
                modal_viewpayment: false,
                modal_viewpaymentdue: false,
                modal_view_carplateno: false,
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            starttime: {item: {start: '', end: ''}},
            endtime: {item: ''},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            show: false,
            counter1: 0,
        }
    },

    components: {create, edit, alert, alertbox2, viewpayment, viewpaymentdue, createentry, viewcarplate},

    async created(){
        await this.getHeaders();
        this.btn_loading = true;
        this.btn_loadingentry = true;
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getCategory();
        await this.getIssue();
        window.addEventListener("unload", (evt) => this.onUnload({operator: this.operator.selected, carpark: this.carpark.selected}, evt));
        this.operator.selected = JSON.parse(AppStorage.get('filter'))?.operator;
        this.carpark.selected = JSON.parse(AppStorage.get('filter'))?.carpark;
        this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            this.items = [];
            
            try{
                this.tableloading = true;
                await this.setTime();
                // this.counter1++;
                let response = await this.$store.dispatch("getParkingEntry", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, search: this.search, startdate: this.starttimeforapi, enddate: this.endtimeforapi });

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].ParkingEntryDateTimeOri = dt[i].ParkingEntryDateTime;
                    dt[i].ParkingEntryDateTime = moment(dt[i].ParkingEntryDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    //replace(/\s/g,'') remove any literal whitespace

                    dt[i].name = dt[i].name == undefined ? 'N/A' : dt[i].name;
                    dt[i].mobile = dt[i].mobile == undefined ? 'N/A' : dt[i].mobile;
                    dt[i].email = dt[i].email == undefined || dt[i].email == "" ? 'N/A' : dt[i].email;
                    dt[i].LastPaidTimeDate = dt[i].LastPaidTimeDate == undefined ? 'N/A' : moment(dt[i].LastPaidTimeDate).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].Amount_Paid = Number(dt[i].Amount_Paid).toFixed(2);
                    dt[i].CarPlateID = dt[i].CarPlateID || 'N/A';
                    dt[i].CarPlateNo = dt[i].CarPlateNo || 'N/A';

                    if (dt[i].ExitMethod == "SEASON"){
                        dt[i].ExitMethod = dt[i].ExitMethod.charAt(0) + dt[i].ExitMethod.toLowerCase().slice(1);
                    }

                    else if (dt[i].ExitMethod == "VALUEPASS"){
                        dt[i].ExitMethod = dt[i].ValuePassName;
                    }

                    else{
                        dt[i].ExitMethod = dt[i].Amount_Due;
                    }
                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                this.btn_loading = false;
                this.btn_loadingentry = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCategory(){
            let list = [];

            try{
                let response = await this.$store.dispatch("getManualExitCategory", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    list.push({name: "Select Category", ID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].DeletedDateTime === null){
                        dt[i].name = `${dt[i].ID} - ${dt[i].CategoryName}`;
                        list.push(dt[i]);
                    }
                }

                }
            }

            catch(err){
                console.log(err);
            }

            this.ma.category = list;
        },

        async getIssue(){
            let list = [];

            try{
                let response = await this.$store.dispatch("getManualExitIssue", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    list.push({name: "Select Issue", ID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].DeletedDateTime === null){
                        dt[i].name = `${dt[i].ID} - ${dt[i].IssueName}`;
                        list.push(dt[i]);
                    }
                }

                }
            }

            catch(err){
                console.log(err);
            }

            console.log(list);

            this.ma.issue = list;
        },

        async statusUpdate(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateSeasonAccessStatus", {seasonaccessid: this.ma.modalInfo.ID, enabledstatus: v.Enabled})
                if (response.data.code === 'AP000'){
                        await this.listItems(1);

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Status has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async addManualExit(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "parkingentryid": Number(v.parkingentryid.item),
                    "parkingtokenamountmyr": Number(v.parkingtokenamountmyr.item),
                    "valuepasspassid": v.valuepasspassid.item == "" ? null : Number(v.valuepasspassid.item),
                    "finalparkingamountmyr": Number(v.finalparkingamountmyr.item),
                    "exitgateid": Number(v.exitgateid.selected),
                    "requestedticketexitdatetime": v.requestedticketexitdatetime.formatted,
                    "reasonformanualexit": v.reasonformanualexit.item,
                    "categoryID": v.category.selected,
                    "issueID": v.issue.selected
                };
                
                let response = await this.$store.dispatch("createManualExit", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Manual Exit Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Invalid Setup`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async addManualEntry(v){
            this.btn_loadingentry = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    "loginmobileid": Number(v.mobileid.item),
                    "gateid": Number(v.gateid.selected),
                    "requestedticketentrydatetime": v.requestedticketentrydatetime.formatted,
                };
                
                let response = await this.$store.dispatch("createManualEntry", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Manual Entry Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Invalid Setup`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loadingentry = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loadingentry = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        getHeaders(){
            if (!this.validateAccess('viewdetails_parkingentry'))
                this.headers.splice(-1, 1);

            else
                this.headers;
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'createentry'){
                this.ma.modal_createentry = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_payment'){
                this.ma.modal_viewpayment = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_paymentdue'){
                this.ma.modal_viewpaymentdue = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }
            
            if(flag == 'view_carplateno'){
                this.ma.modal_view_carplateno = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }



            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_viewpayment = value;
                this.ma.modal_viewpaymentdue = value;
                this.ma.modal_createentry = value;
                this.ma.modal_view_carplateno= value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addManualExit(value);
            }

            if (value.flag === 'createentry'){
                this.ma.confirmCreate = true;
                this.ma.modal_createentry = false;
                this.addManualEntry(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.statusUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === true){ return 'Enable'}
                    else if (val === false){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === true){ return 'green'}
                    else if (val === false){return 'red'}
                    else {return 'grey'}
            }
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },
         setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start).toUTCString()).format('YYYY-MM-DD HH:mm:ss') : null;
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end).toUTCString()).format('YYYY-MM-DD HH:mm:ss') : null;
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime).add(1, 'days'));
                this.starttime.item.end = new Date(endtime);
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            if(starttime != null && ( starttime.includes('1970') || starttime.includes('Invalid date'))){
                starttime = null
            }
            if(endtime != null && (endtime.includes('1970') || endtime.includes('Invalid date'))){
                endtime = null
            }
            
            this.starttimeforapi = starttime;
            this.endtimeforapi = endtime;

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null
        },
         cleardayplusone(v){
            let dte2 = new Date(moment(v.start).add(31, 'days'));
            this.dayplusone = [
                {
                start: dte2,
                end: null
                },
            ];
        },
        isToday() {
            return moment(new Date()).format('DD-MM-YYYY');
        },
    }
}
</script>