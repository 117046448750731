<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="750">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Controller Ref: {{modalInfo.WLControllerRef}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay
                :absolute="true"
                :opacity="1"
                :z-index="999"
                :value="modal_loader"
                color="#D2D7DF"
                >
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-overlay>
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.WLControllerID}}</td>
                            <td>{{props.item.WLControllerRef}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="showConnect(props.item.LastSeenDate, 'color')"
                                text-color="white">
                                    {{showConnect(props.item.LastSeenDate, 'text')}}
                                </v-chip>
                            </td>
                            <td>{{props.item.LastSeenDate}}</td>
                        </tr>
                    </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            /*31. Controller ID, Controller Ref., Last Seen, Date Last Seen -> display N/A if no value -> {{url}}/api/gatecontroller/view/bygatecontrollerid :warning:  */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Controller ID', value: 'GateControllerID', width: '3%' },
                { text: 'Controller Ref', value: 'RefControllerID', width: '3%' },
                { text: 'Last Seen', value: 'LastSeen', width: '3%' },
                { text: 'Date Last Seen', value: 'lastSeenDate2', width: '12%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1,
            modal_loader: true,
            amount_due_color: ''
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];
            this.tableloading = true;
            this.modal_loader = true;
            let response = await this.$store.dispatch('getBayLockControllerItem', {wlcontrollerid: this.modalInfo.WLControllerID})
            if (response.data.code == "AP000"){
                let dt = response.data.record;
                console.log(dt);
                dt.WLControllerLastAliveDateTime = dt.WLControllerLastAliveDateTime == null ? "N/A" : moment(dt.WLControllerLastAliveDateTime).format('DD-MM-YYYY hh:mm:ss A');
                dt.LastConnectedDate = dt.LastConnectedDate == null ? "N/A" : moment(dt.LastConnectedDate).format('DD-MM-YYYY hh:mm:ss A');
                dt.LastDisconnectDate = dt.LastDisconnectDate == null ? "N/A" : moment(dt.LastDisconnectDate).format('DD-MM-YYYY hh:mm:ss A');
                dt.LastSyncRequestDateTime = dt.LastSyncRequestDateTime == null ? "N/A" : moment(dt.LastSyncRequestDateTime).format('DD-MM-YYYY hh:mm:ss A');
                // dt.LastSeenDate = dt.LastSyncedDateTime == null ? "N/A" : moment(dt.LastSyncedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                dt.LastSeenDate = dt.LastSeenDate == null ? "N/A" : moment(dt.LastSeenDate).add(8, 'hours').format('DD-MM-YYYY hh:mm:ss A');

                this.items.push(dt);
            }

            this.tableloading = false;
            this.modal_loader = false;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },

        showConnect(item, typ){
            if (typ == 'text'){
                console.log(item);
                console.log(moment(item, "DD-MM-YYYY hh:mm:ss A"));
                    if (moment().diff(moment(item, "DD-MM-YYYY hh:mm:ss A"), 'minutes') <= 5)
                        return 'Connected';

                    else
                        return 'Disconnected';
            }

            if (typ == 'color'){
                    if (moment().diff(moment(item, "DD-MM-YYYY hh:mm:ss A"), 'minutes') <= 5)
                        return 'green';

                    else
                        return 'red';
            }
        },
    }
  }
</script>