<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Create Controller</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    @change="execFilter"
                    :items="form.operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    :disabled="form.operator.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.RefControllerID.item"
                        :rules="form.RefControllerID.rule"
                        label="Controller Ref."
                        required
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Type"
                    v-model="form.GateControllerType.selected"
                    :items="form.GateControllerType.items"
                    item-text="name"
                    item-value="ID"
                    :rules="form.GateControllerType.rule"
                    :loading="form.GateControllerType.loader"
                    :disabled="form.GateControllerType.disable"
                    >
                    </v-autocomplete>
                    <!--
                    <v-text-field
                        dense
                        outlined
                        v-model="form.PoleDesc.item"
                        :rules="form.PoleDesc.rule"
                        label="Pole No."
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.GateDesc.item"
                        :rules="form.GateDesc.rule"
                        label="Gate No."
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                    ></v-text-field>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="#25283D"
                    class="col-1 pl-0"
                    v-model="form.Enabled.item"
                    :label="`${form.Enabled.item == 1 ? 'Enabled' : 'Disabled'}`"
                    ></v-switch>
                    -->
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                Tokens: {item: ""}, //wait backend
                RefControllerID: {item: "", rule: []},
                TokenRef: {item: ""}, //wait backend
                GateControllerID: {item: "", rule: []},
                Operator: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                GateControllerType: {items: [{ID: '', name: "Select"}, {ID: 'A', name: "A"}, {ID: 'B', name: "B"}, {ID: 'C', name: "C"}, {ID: 'D', name: "D"}], selected: "", rule: []},
                PoleDesc: {item: "", rule: []},
                GateDesc: {item: "", rule: []},
                Remark: {item: "", rule: []},
                Enabled: {item: "", rule: []},
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.carpark.rule = [
                v => !!v || 'This is required',
            ];

            this.form.RefControllerID.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.GateControllerType.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.form.RefControllerID.rule = [];
            this.form.GateControllerType.rule = [];
            this.form.carpark.rule = [];

            this.form.operator.selected = "";
            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            for (const [index, item] of this.operator.entries()){
                operator.push(item);
            }
            this.form.operator.items = operator;

            this.form.carpark.selected = "";
            let carpark = [];
            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.RefControllerID.item = "";
            this.form.GateControllerType.selected = "";
        },

        execFilter(){
            this.form.carpark.selected = "";
            let filteredcarparkfromoperatorid = this.carpark.filter(item => item.OperatorID == this.form.operator.selected);
            let carpark = [];
            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of filteredcarparkfromoperatorid.entries()){
                carpark.push(item);
            }

            if (this.form.operator.selected != "")
            this.form.carpark.items = carpark;

            else
            this.form.carpark.items = this.carpark;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            this.form.RefControllerID.item = Number(this.form.RefControllerID.item);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>