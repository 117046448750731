<template>
  <v-container fill-height="fill-height" style="opacity: .91">
        <v-layout align-center="align-center" justify-center="justify-center">
                <v-fade-transition mode="out-in">

          <v-flex class="login-form text-xs-center"> 
            <div class="display-1 mb-3 white--text">
              <v-row dense>
                    <v-col cols="2">
                        <img style="max-width: 100%; height: auto" src="../assets/logo.png" />
                    </v-col>
                    <v-col cols="10" style="align-self: center">
                        <img style="max-width: 100%; height: auto" src="../assets/snatch_word_yellow.png" />
                    </v-col>
                </v-row>Welcome to Snatch
            </div>
            <v-card light="light">
              <v-card-text>
                <div class="subheading">
                  <template>Register your email address</template>
                </div>
                <v-form ref="loginForm" action="#" @submit.prevent="validateBeforeSubmit" lazy-validation>
                  <v-text-field required :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Please enter an email address']" v-model="user.name" light="light" prepend-icon="fa fa-user" label="Email" type="text"></v-text-field>
                  <v-btn tile color="primary" block="block" type="submit" :loading="loading" :disabled="loading" class="mt-2">
                        Register
                        <template v-slot:loader>
                            <span>Loading...</span>
                        </template>
                  </v-btn>                  
                </v-form>
                <v-row>
                      <v-col cols="12" md="12"><span><v-icon x-small>fas fa-backward</v-icon> <a href="#"><u><router-link to="/">Return to Login page</router-link></u></a></span></v-col>
                  </v-row>   
              </v-card-text>
            </v-card>
          </v-flex>
                </v-fade-transition>
          <v-footer app="app">
      <v-flex class="caption">Copyright By Snatch Park Bizsolutions Sdn Bhd © 2021</v-flex>
    </v-footer>
        </v-layout>
        <v-dialog
        v-model="dialogLoader"
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Logging In...
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
        <alertbox :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
      </v-container>
</template>

<script>
import timer from '../assets/js/sleeper';
import alertbox from '../components/modals/Notification';
import {ERR} from '../assets/js/errhandle.js';
import * as AppStorage from '../assets/js/storage';

export default {
    name: 'register',

    data(){
        return {
            options: {
                isLoggingIn: '',
                shouldStayLoggedIn: true,
            },

            user: {
                name: '',
                password: ''
            },
            loading: false,
            dialogLoader: false,
            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
        }
    },

    components: {
        alertbox
    },

    created(){
        //this.test1();
    },

    methods: {
        async userReg(){
            this.loading = true
            this.dialogLoader = true

            try{
                let response = await this.$store.dispatch("userRegister", {
                                    username: this.user.name,
                                    })

                await timer.sleep(100);
                if (response.data.code === 'AP000')
                {
                    AppStorage.set('tempitem', 'registered');
                    this.loading = false;
                    this.dialogLoader = false;
                    this.ex.modal = true;
                    this.ex.content.headline = 'Email Registered!';
                    this.ex.content.icon = 'fa fa-check';
                    this.ex.content.chipColor = '#a0e8af';
                    this.ex.content.iconColor = 'green';
                    this.ex.content.maintext = 'Please check your email for verification link.';
                    this.ex.content.smalltext = 'You may now close this screen or return to Login page.';
                }

                else if (response.data.code === 'RD002')
                {
                    this.loading = false;
                    this.dialogLoader = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This email is already registered`;
                }

                else
                {
                    this.loading = false;
                    this.dialogLoader = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.loading = false;
                this.dialogLoader = false;
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'API Error';
            }
        },

        onClosed(value){
            if (value == false){
                this.autoRefresh = 1;
                this.ex.modal = value;
            }
        },

        validateBeforeSubmit() {
            if (this.$refs.loginForm.validate()) {
                this.userReg();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-form{
    max-width: 400px !important;
}
</style>