<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="1250">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('updateplate')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Update Plate No.</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                    </v-overlay>
                     <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Car Park"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    @change="filterCarpark"
                    >
                    </v-autocomplete>
                    <div v-if="form.carpark.selected != ''">
                        <v-autocomplete
                            dense
                            outlined
                            placeholder="Select Entry ID"
                            label="Entry ID *"
                            v-model="form.parkingentryid.selected"
                            :items="form.parkingentryid.items"
                            item-text="name"
                            item-value="PlateNo"
                            :rules="form.exitgateid.rule"
                            :loading="form.exitgateid.loader"
                            :disabled="form.exitgateid.disable"
                            @change="onSelectedEntry"
                        >
                        </v-autocomplete>
                        <v-text-field
                            dense
                            outlined
                            v-model="form.carplate.item"
                            :rules="form.carplate.rule"
                            label="New Car Plate *"
                        ></v-text-field>
                        <v-row>
                            <v-col>
                                <h3  v-if="loadImageEntry != ''">Entry ID:</h3>
                                <div class="" v-if="loadImageEntry != ''">
                                    <image-zoom 
                                        img-class="height"
                                        :regular="loadImageEntry"
                                    >
                                    </image-zoom>
                                </div>
                            </v-col>
                        </v-row>
                    </div>


                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Submit</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'allInfo', 'category', 'issue', 'carpark'],

    data() {
        return {
            dialogLocal: false,
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                parkingentryid: {item: "", rule: [], selected: '', items: [], itemsFull: []},
                parkingtokenamountmyr: {item: "", rule: []},
                valuepasspassid: {item: "", rule: []},
                finalparkingamountmyr: {item: "", rule: []},
                exitgateid: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    selectedforapi: {},
                    loader: false,
                    disable: false,
                    rule: []
                },
                lprimages: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
               carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                carplate: {item: "", rule: []},
                requestedticketexitdatetime: {item: "", formatted: "", rule: []},
                category: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                issue: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                reasonformanualexit: {item: "", rule: []},
            },
            modal_loader: false,
            focused: false,
            disabled_dates: [],
            loadImageExit: '',
            loadImageEntry: false,
        }
    },

    components: {alertbox, VueTimepicker},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.form.requestedticketexitdatetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.requestedticketexitdatetime.item == '' && this.form.requestedticketexitdatetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.requestedticketexitdatetime.item == '' && this.form.requestedticketexitdatetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getGateFull(){
            let list = [];
            this.form.exitgateid.items = [];
            this.form.exitgateid.selected = "";

            try{
                this.form.exitgateid.loader = true;
                this.form.exitgateid.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getGateDropDown", {search: ''});
                let dt = response.data.record;
                console.log(dt)
                if (response.data.code == 'AP000'){
                    this.form.exitgateid.items.push({name: "Select Exit Gate", GateID: "", CarParkID: "", GateUUID: ''});
                    this.form.exitgateid.itemsFull.push({name: "Select Exit Gate", GateID: "", CarParkID: "", GateUUID: ''});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].IsEntryGate === 0){
                        dt[i].name = `${dt[i].GateID} - ${dt[i].RefGateID}`;
                        this.form.exitgateid.items.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID, GateUUID: dt[i].GateUUID});
                        this.form.exitgateid.itemsFull.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID, GateUUID: dt[i].GateUUID});
                    }
                }
                }

                this.form.exitgateid.loader = false;
                this.form.exitgateid.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async beforeCreateForm(flag){

            this.form.exitgateid.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.exitgateid.rule = [
                v => !!v || `This is required`,
            ];

            this.form.category.rule = [
                v => !!v || `This is required`,
            ];

            this.form.issue.rule = [
                v => !!v || `This is required`,
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            await this.getGateFull();
            this.disabled_dates = [];
            this.form.parkingentryid.rule = []; this.form.parkingtokenamountmyr.rule = []; this.form.valuepasspassid.rule = []; this.form.finalparkingamountmyr.rule = [];
            this.form.exitgateid.rule = []; this.form.requestedticketexitdatetime.rule = []; this.form.reasonformanualexit.rule = [];
            this.form.category.rule = []; this.form.issue.rule = [];

            this.form.parkingentryid.item = "";
            this.form.parkingtokenamountmyr.item = "";
            this.form.valuepasspassid.item = "";
            this.form.finalparkingamountmyr.item = "";
            this.form.exitgateid.item = "";
            this.form.requestedticketexitdatetime.item = "";
            this.form.requestedticketexitdatetime.formatted = "";
            this.form.reasonformanualexit.item = "";
            this.form.category.selected = "";
            this.form.issue.selected = "";
            this.form.category.items = this.category;
            this.form.category.itemsFull = this.category;
            this.form.issue.items = this.issue;
            this.form.issue.itemsFull = this.issue;
            this.loadImageEntry = '';
            this.loadImageExit = '';
            this.form.parkingentryid.selected = '';
            this.form.carpark.selected = "";
            this.form.exitgateid.selectedforapi = {};
            let carpark = [];
            carpark.push({name: 'Select Car Park', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.parkingentryid.items.push({name: 'Select Parking Entry ID', ParkingEntryId: '', PlateNo: '', CarParkID: ''})
        },

        async filterGate(val){
            this.form.exitgateid.selectedforapi = this.form.exitgateid.itemsFull.find((v)=> v.GateID == val);
            console.log(val);
            console.log(this.form.exitgateid.selectedforapi);
            await this.getLatestLPRImage();  

        },
        async filterCarpark(){
            this.form.exitgateid.items = this.form.exitgateid.itemsFull.filter((item)=> item.CarParkID == this.form.carpark.selected)
            const selectedCarpark = this.form.carpark.items.filter((item)=> this.form.carpark.selected == item.CarParkID)[0];
            
            let response = await this.$store.dispatch("getLPRParkingEntry", {page: 1, search: null, startdate: null, enddate: null, rowresultsperpage: 999999, operatorid: selectedCarpark.OperatorID, carparkid: this.form.carpark.selected });
            console.log(response[0]);
            let dt = response.data.record;
            let recordCount = response.data.recordcount[0].resultcount;
            for(var i=0; i<dt.length; i++){
                dt[i].ParkingEntryDateTime = moment(dt[i].ParkingEntryDateTime).format('DD-MM-YYYY hh:mm:ss A');

                this.form.parkingentryid.items.push({name: dt[i].ParkingEntryID + ' ' + dt[i].CarPlateNo + ' ' + dt[i].ParkingEntryDateTime ,ParkingEntryId: dt[i].ParkingEntryID, PlateNo: dt[i].CarPlateNo, CarParkID: dt[i].CarParkID})
            }
        },
        

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            let formattedrequestedticketexitdatetime = moment(this.form.requestedticketexitdatetime.item).format('YYYY-MM-DD HH:mm:ss');
            this.form.requestedticketexitdatetime.formatted = formattedrequestedticketexitdatetime;
            console.log(this.ma);
                if (this.ma.modal_action_type == 'updateplate'){
                    this.form.flag = 'updateplate';
                    const rq = {
                        carparkid: this.form.carpark.selected,
                        old: this.form.parkingentryid.selected,
                        new: this.form.carplate.item,
                        flag: this.form.flag
                    }
                    console.log(rq);
                    this.$emit('confirm', rq);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        execFilter(id){
            if (id !== 'category') return;

            this.form.issue.selected = "";

            if (this.form.category.selected !== ''){
                let [first, ...rest] = this.form.issue.itemsFull;
                console.log('this.form.issue.itemsFull', this.form.issue.itemsFull);
                let issue = this.form.issue.itemsFull.filter(itm => itm.CategoryID === this.form.category.selected);
                issue.unshift(first);
                console.log('issue', issue);
                this.form.issue.items = issue;
            }

            else{
                let [...issue] = this.form.issue.itemsFull;
                console.log('issue', issue);
                this.form.issue.items = issue;
            }
        },

        searchTimeOut() {
          this.form.exitgateid.loader = true;
          this.form.exitgateid.disable = true;
          this.form.requestedticketexitdatetime.item = "";
          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.filterGate();
          let entryidfilter = this.allInfo.find(item => item.ParkingEntryID == this.form.parkingentryid.item);
          console.log('entryidfilter', entryidfilter);
          if (entryidfilter != undefined){
              let dte = new Date(moment(entryidfilter.ParkingEntryDateTimeOri).subtract('1', 'days').format('MM/DD/YYYY HH:mm:ss'));
              console.log('entryidfilter.ParkingEntryDateTimeOri', entryidfilter.ParkingEntryDateTimeOri);
            this.disabled_dates = [
                {
                start: null,
                end: dte
                },
            ];
          }
          }, 800);
        },
        onSelectedExit(){
            console.log(this.form.lprimages.selected);
            console.log( this.form.lprimages.itemsFull.find((v)=> v.PlateNo == this.form.lprimages.selected).ImageUrl); 
            this.loadImageExit = this.form.lprimages.itemsFull.find((v)=> v.PlateNo == this.form.lprimages.selected).ImageUrl;
        },
        async onSelectedEntry(val){
            console.log(val)
            try {
                let response = await this.$store.dispatch('getLprCarplate', {carplateNo: val, carparkid: this.form.carpark.selected, imageType: 'entry'})
                let dt = response.data;
                if(dt.length > 0){
                    this.loadImageEntry = dt[0].ImageUrl;
                } else {
                    this.loadImageEntry = '';   
                }
            } catch (error) {
             console.log(error)   
            }
        },
        async getLatestLPRImage(){
            this.form.lprimages.items =[];
            this.form.lprimages.selected = '';

            try{
                this.form.lprimages.loader = true;
                this.form.lprimages.disable = true;

                let response = await this.$store.dispatch("getLPRImageDropDown", {carparkid: this.form.carpark.selected, gateid: this.form.exitgateid.selectedforapi.GateID});
                let dt = response.data;
                console.log(dt);
                if (response.data.length>0){
                    this.form.lprimages.items.push({name: "Select Image", PlateNo: "", CarParkID: "",ImageUrl: ''});
                    this.form.lprimages.itemsFull.push({name: "Select Image", PlateNo: "", CarParkID: "",ImageUrl: ''});

                for(var i=0; i<dt.length; i++){
                    dt[i].CreatedDateTime = moment(dt[i].CreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].name = `${dt[i].PlateNo} - ${dt[i].CreatedDateTime}`;
                    this.form.lprimages.items.push({name: dt[i].name,ImageUrl: dt[i].ImageUrl, PlateNo: dt[i].PlateNo, CarParkID: dt[i].CarParkID});
                    this.form.lprimages.itemsFull.push({name: dt[i].name,ImageUrl: dt[i].ImageUrl, PlateNo: dt[i].PlateNo, CarParkID: dt[i].CarParkID});
                }
                }

                this.form.lprimages.loader = false;
                this.form.lprimages.disable = false;
            }catch(err){
                console.log(err);
            }

        }
    }
  }
</script>
<style>
.contain,
.vh--outer {
  width: 100%;
  height: 550px;
}
.vh--holder {
  height: calc(100%);
}
.height,
.vh--holder {
  width: 100%;
}
</style>