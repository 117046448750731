<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            {{readonly ? 'View' : 'Update'}} Parking Token {{` ${modalInfo.ParkingTokenBundleID}`}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.ParkingTokenBundleID"
                        label="ID"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.parkingtokens.item"
                        :rules="form.parkingtokens.rule"
                        label="Parking Tokens Amount"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.unitprice.item"
                        :rules="form.unitprice.rule"
                        label="Cost (RM)"
                        required
                        :disabled="readonly"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.ServiceFeePercentage"
                        label="Service Fees (%)"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.ServiceFeeFinalTotal"
                        label="Service Fees (RM)"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.GrandFinalTotal"
                        label="Total Cost (RM)"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CreatedDateTime"
                        label="Created Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.UpdatedDateTime"
                        label="Updated Date"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.ActionByAdminID_Name"
                        label="Last Update Person"
                        required
                        disabled
                    ></v-text-field>
                    <span class="text-body-2">Display on Mobile</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.displayonmobile.item"
                    :label="`${form.displayonmobile.item ? 'Yes' : 'No'}`"
                    :disabled="readonly"
                    ></v-switch>
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form.status.item"
                    :label="`${form.status.item ? 'Enabled' : 'Disabled'}`"
                    :disabled="readonly"
                    ></v-switch>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn v-if="validateAccess('ptm_delete') && modalInfo.ActiveStatus == 0" tile color="error darken-1" small @click="handleDelete('delete')" class="ml-2">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                parkingtokens: {item: '', rule: []},
                unitprice: {item: '', rule: []},
                displayonmobile: {item: ''},
                status: {item: ''},
            },

            btn_loading: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.parkingtokens.rule = [];
            this.form.unitprice.rule = [];
        },

        async beforeCreateForm(flag){
            this.form.parkingtokens.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.unitprice.rule = [
                v => !!v || 'This is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.onRuleChange();
            this.form.parkingtokens.item = this.modalInfo.TokenAmount;
            this.form.unitprice.item = this.modalInfo.TokenPriceFinalTotal;
            this.form.displayonmobile.item = this.modalInfo.MobileDisplayStatus == 1 ? true : false;
            this.form.status.item = this.modalInfo.ActiveStatus == 1 ? true : false;
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.form.displayonmobile.item = this.form.displayonmobile.item == true ? 1 : 0;
                    this.form.status.item = this.form.status.item == true ? 1 : 0;
                    this.$emit('confirm', this.form);
                }

                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>