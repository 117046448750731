<template>
<v-row justify="center">
<v-dialog v-model="dialogLocal" width="450">
    <v-card>
    <v-form ref="es" action="#" lazy-validation>
    <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
        <span class="headline">BayLock ID: {{modalInfo.WLWheelLockID}}</span>
        <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
        <v-container fluid>
        <v-row>
            <v-col cols="12" md="12">
                <v-text-field
                    dense
                    outlined
                    :value="`${modalInfo.WLWheelLockID} - ${modalInfo.WLWheelLockRef}`"
                    label="BL ID - BL Ref."
                    required
                    disabled
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    v-model="modalInfo.BayID"
                    label="Bay ID"
                    required
                    disabled
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    :value="`${modalInfo.CarParkBayPrefix} - ${modalInfo.CarParkBayNo}`"
                    label="CP Prefix - Bay Number"
                    required
                    disabled
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    v-model="modalInfo.LastConnectedDateTime"
                    label="Last Register Date"
                    required
                    disabled
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    v-model="modalInfo.LastDisconnectedDateTime"
                    label="Last Deregister Date"
                    required
                    disabled
                ></v-text-field>
            </v-col>
        </v-row>
        </v-container>
    </v-card-text>
    </v-form>
    </v-card>
</v-dialog>
</v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

data() {
    return {
        dialogLocal: false,
        items: {},
        headers: [
            { text: '#', value: 'row' },
            { text: 'Parking ID', value: 'ParkingEntryID' },
            { text: 'CP ID', value: 'CarParkID' },
            { text: 'Car Park', value: 'CarParkName' },
            { text: 'Mobile No.', value: 'CurrentCustomerMobile'},
            { text: 'Date Of Entry', value: 'ParkingEntryDateTime', width: '10%', sort: function (a, b) {
                let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                let aa2 = aa.toDate();

                let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                let bb2 = bb.toDate();

                return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
            }  },
            { text: 'Parking Duration (dd:hh:mm)', value: 'Duration' },
            { text: 'Exit Method', value: 'ExitMethod' },
            { text: 'VP ID', value: 'ValuePassID' }, //need to update to actual param
            { text: 'VP Unit ID', value: 'ValuePassUnitID' }, //need to update to actual param
            { text: 'Value Pass Name', value: 'ValuePassName' },
            { text: 'Total Parking Tokens', value: 'TotalTokens' }, //need to update to actual param
            { text: 'Current Fees (RM)', value: 'Amount_CurrentFees' },
            { text: 'Pending Paid (RM)', value: 'Amount_Due' },
            { text: 'Next 2 Hours Fees (RM)', value: 'Amount_FutureFees' }, //need to update to actual param
        ],
        tableloading: false,
        swal: {
            notification: false,
            message: '',
            scolor: '',
        },
        page: 1,
        modal_loader: true,
        amount_due_color: ''
    }
},

watch: {
    dialog: {
    handler: function(value) {
        this.modalHandler();
        if(value == true){
            this.handleValue();
        }
    },
    },

    dialogLocal: {
    handler: function(value) {
        this.modalCloseHandler();
    },
    },
},

methods: {
    async listAll(){
        this.items = {};
        this.tableloading = true;
        this.modal_loader = true;

        let response = await this.$store.dispatch('getBayLockControllerItem', {wlcontrollerid: this.modalInfo.WLControllerID})
        if (response.data.code == "AP000"){
            let dt = response.data.record;

            dt.WLControllerLastAliveDateTime = dt.WLControllerLastAliveDateTime == null ? "N/A" : moment(dt.WLControllerLastAliveDateTime).format('DD-MM-YYYY hh:mm:ss A');
            dt.LastConnectedDate = dt.LastConnectedDate == null ? "N/A" : moment(dt.LastConnectedDate).format('DD-MM-YYYY hh:mm:ss A');
            dt.LastDisconnectDate = dt.LastDisconnectDate == null ? "N/A" : moment(dt.LastDisconnectDate).format('DD-MM-YYYY hh:mm:ss A');
            dt.LastSyncRequestDateTime = dt.LastSyncRequestDateTime == null ? "N/A" : moment(dt.LastSyncRequestDateTime).format('DD-MM-YYYY hh:mm:ss A');
            dt.LastSyncedDateTime = dt.LastSyncedDateTime == null ? "N/A" : moment(dt.LastSyncedDateTime).format('DD-MM-YYYY hh:mm:ss A');

            this.items = dt;
        }

        this.tableloading = false;
        this.modal_loader = false;
    },

    modalHandler(){
        this.dialogLocal = !this.dialogLocal;
    },

    modalCloseHandler(){
        if (this.dialogLocal == false){
            this.$emit('closed', false);
        }
    },

    handleValue(){
        //this.listAll();
        /*
        this.items.parkerID = this.modalInfo.parkerID;
        this.items.name = this.modalInfo.name;
        this.items.mobile = this.modalInfo.mobile;
        this.items.email = this.modalInfo.email;
        */
    },
}
}
</script>