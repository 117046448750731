<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Touch N Go e-Wallet</v-card-title>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <vc-date-picker
                     :key="counter1" v-model="starttime.item.start" mode="date" popover @input="listItems(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="Date"
                                outlined
                                autocomplete="off"
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            -->
            <v-row dense>
                <!-- <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col> -->
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{props.item.selectedDate}}</td>
                            <td>
                            <v-tooltip top v-if="validateAccess('tng_download')">
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" id="custom-hover" @click.prevent="downloadFile(props.item.name, starttimeforapi, props.item.rowloader)">
                                        <span>{{props.item.name}} </span>
                                        <v-progress-circular
                                        v-if="props.item.rowloader"
                                        indeterminate
                                        :size="15"
                                        color="blue"
                                        ></v-progress-circular>
                                        <v-icon v-else x-small color="blue">fa fa-download</v-icon></a>
                                </template>
                                <span>Download</span>
                                </v-tooltip>
                                <span v-else>{{props.item.name}} </span>
                            </td>
                            <td>{{props.item.size}}</td>
                            <td>{{props.item.modifyTime}}</td>
                            <td>{{props.item.accessTime}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSystem';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF2';
import dpicker from "../DatePickerComponent";
import XLSX from 'xlsx';

export default {
    name: 'Touch N Go e-Wallet',
    title: 'Touch N Go e-Wallet',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },

                {
                text: 'Payment Gateway',
                disabled: true,
                href: '#',
                },

                {
                text: 'Touch N Go e-Wallet',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            headers: [
                { text: 'Date', value: 'selectedDate', width: '3%' },
                { text: 'File Name', value: 'name', width: '3%' },
                { text: 'Size', value: 'size', width: '3%' },
                { text: 'Modify Date', value: 'modifyTime', width: '3%' },
                { text: 'Access Date', value: 'accessTime', width: '3%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_pdf: '',
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            starttime: {item: {start: '', end: ''}},
            endtime: {item: ''},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            show: false,
            counter1: 0,
            dataforexport: [],
            reportloader: false
        }
    },

    components: {create, alert, alertbox2, viewpdf, dpicker},

    async created(){
        await this.getOperatorFull();
        await this.getCarParkFull();
        //await this.setTime();
        await this.listItems(1);
        this.show = true;
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            try{
                this.tableloading = true;
                await this.setTime();
                this.counter1++;
                let response = await this.$store.dispatch("getTNGFinanceSFTP", {folderyyyymmdd: this.starttimeforapi });

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                //let recordCount = response.data.recordcount[0].resultcount;
                //this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].selectedDate = moment(this.starttime.item.start).format('DD-MM-YYYY');
                    dt[i].modifyTime = moment(dt[i].modifyTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].accessTime = moment(dt[i].accessTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].rowloader = false;
                    //replace(/\s/g,'') remove any literal whitespace
                }
                
                this.items = dt;
                //this.pages = Math.ceil(recordCount / 100);
                //await this.getForReport();
                }

                else{
                    this.items = [];
                }

                this.tableloading = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async getForReport(){
            this.reportloader = true;
            let response = await this.$store.dispatch("getBoostReport", {page: 1, search: this.search, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, rowresultsperpage: 99999 });
            let dt = response.data.record;
            const arr = [];

            dt.forEach((doc, i) => {
                //ID, Ref No., Mobile ID, Name, Mobile No., Email, Transaction Date, Free Tokens, Amount (RM)
                    let appData = doc;
                    appData.row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    appData.SPPAY_RequestCreatedDateTime = doc.SPPAY_RequestCreatedDateTime == null ? "N/A" : moment(new Date(doc.SPPAY_RequestCreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    appData.Amount = Number(appData.Amount).toFixed(2);
                    appData.SPPAY_AmountFinanceFee = Number(appData.SPPAY_AmountFinanceFee).toFixed(2);
                    appData.SPPAY_AmountFinanceFeeSST = Number(appData.SPPAY_AmountFinanceFeeSST).toFixed(2);
                    appData.SPPAY_AmountNetAfterFee = Number(appData.SPPAY_AmountNetAfterFee).toFixed(2);
                    arr.push({
                        row: appData.row,
                        BoostRefNo: appData.BoostRefNo,
                        PaymentProvider3rdPartyRefNo: appData.PaymentProvider3rdPartyRefNo,
                        PassRef: appData.PassRef,
                        SPPAY_MerchantID: appData.SPPAY_MerchantID,
                        SPPAY_PaymentOptionName: appData.SPPAY_PaymentOptionName,
                        SPPAY_CarParkID: appData.SPPAY_CarParkID,
                        CarParkName: appData.CarParkName,
                        name: appData.name,
                        mobile: appData.mobile,
                        email: appData.email,
                        SPPAY_RequestCreatedDateTime: appData.SPPAY_RequestCreatedDateTime,
                        Product: appData.Product,
                        Amount: appData.Amount,
                        SPPAY_AmountFinanceFee: appData.SPPAY_AmountFinanceFee,
                        SPPAY_AmountFinanceFeeSST: appData.SPPAY_AmountFinanceFeeSST,
                        SPPAY_AmountNetAfterFee: appData.SPPAY_AmountNetAfterFee,
                    });
            })

            let total1 = arr.reduce(function (acc, obj) { return acc + Number(obj.Amount); }, 0);
            let total2 = arr.reduce(function (acc, obj) { return acc + Number(obj.SPPAY_AmountFinanceFee); }, 0);
            let total3 = arr.reduce(function (acc, obj) { return acc + Number(obj.SPPAY_AmountFinanceFeeSST); }, 0);
            let total4 = arr.reduce(function (acc, obj) { return acc + Number(obj.SPPAY_AmountNetAfterFee); }, 0);

            arr.push({
                        row: "",
                        BoostRefNo: "",
                        PaymentProvider3rdPartyRefNo: "",
                        PassRef: "",
                        SPPAY_MerchantID: "",
                        SPPAY_PaymentOptionName: "",
                        SPPAY_CarParkID: "",
                        CarParkName: "",
                        name: "",
                        mobile: "",
                        email: "",
                        SPPAY_RequestCreatedDateTime: "",
                        Product: "Grand Total",
                        Amount: total1.toFixed(2),
                        SPPAY_AmountFinanceFee: total2.toFixed(2),
                        SPPAY_AmountFinanceFeeSST: total3.toFixed(2),
                        SPPAY_AmountNetAfterFee: total4.toFixed(2),
                    });

            this.dataforexport = arr;
            this.reportloader = false;
            this.onexport();
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.carpark.itemsFull.push(dt[i]);
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                this.pdfurl = row.InvoiceReceiptURL;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
            }
        },

        /*
        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },
        */

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        /*
        dateValStartTime(value){
            this.starttime.item = value;
            this.execFilter('date');
        },

        dateValEndTime(value){
            this.endtime.item = value;
            this.execFilter('date');
        },
        */

        setTime(){
            console.log('this.starttime.item.start', this.starttime.item.start);
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start)).format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime));
            }
            console.log('starttime', starttime);
            this.starttimeforapi = moment(starttime).format('YYYYMMDD');

            // Add a day
            this.dayplusone = null;
        },

        async downloadFile(name, date){
            let itms = this.items.filter(itm => itm.name == name);
            for(var i=0; i<itms.length; i++){
                itms[i].rowloader = true;
            }

            try{
                let response = await this.$store.dispatch("getTNGFinanceSFTPBase64", {folderyyyymmdd: date, filenamewithext: name });

                if (response.data.code == 'AP000'){
                let dt = atob(response.data.record);
                this.exportCSVFile({ name: 'Name' }, dt, name);
                }

                for(var i=0; i<itms.length; i++){
                    itms[i].rowloader = false;
                }

                this.items = itms;
            }

            catch(err){
                console.log(err);
            }
        },

        exportCSVFile(headers, items, fileTitle) {
            const csv = items;
            const exportedFilenmae = fileTitle + '.csv' || 'export.csv'; // eslint-disable-line
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                const link = document.createElement('a');
                if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },

        isToday() {
            return moment(new Date()).format('DD-MM-YYYY');
        },

        onexport () { // On Click Excel download button
        // export json to Worksheet of Excel
        // only array possible
        /*
        7. #, Ref. No., Transaction ID, Trxn Ref., Merchant ID, Payment Method, CP ID, Car Park, Name, Mobile No., Email, Transaction Date, Product, Amount (RM), Finance (RM), Finance SST (RM), Net Amount (RM)
        */
        var eHeaders = XLSX.utils.json_to_sheet(this.dataforexport, { origin: 'A6'})
        // A workbook is the name given to an Excel file
            eHeaders.A6.v = '#';
            eHeaders.B6.v = 'Ref. No.';
            eHeaders.C6.v = 'Transaction ID';
            eHeaders.D6.v = 'Trxn Ref.';
            eHeaders.E6.v = 'Merchant ID';
            eHeaders.F6.v = 'Payment Method';
            eHeaders.G6.v = 'CP ID';
            eHeaders.H6.v = 'Car Park';
            eHeaders.I6.v = 'Name';
            eHeaders.J6.v = 'Mobile No.';
            eHeaders.K6.v = 'Email';
            eHeaders.L6.v = 'Transaction Date';
            eHeaders.M6.v = 'Product';
            eHeaders.N6.v = 'Amount (RM)';
            eHeaders.O6.v = 'Finance (RM)';
            eHeaders.P6.v = 'Finance SST (RM)';
            eHeaders.Q6.v = 'Net Amount (RM)';

        var wb = XLSX.utils.book_new() // make Workbook of Excel
        const sheet_name_list = wb.SheetNames;
        console.log('sheet_name_list', sheet_name_list);


        XLSX.utils.sheet_add_aoa(eHeaders, [["Snatch Park Bizsolutions Sdn Bhd"]], {origin: 'A1'});
        XLSX.utils.sheet_add_aoa(eHeaders, [["Boost Report"]], {origin: 'A3'});
        XLSX.utils.sheet_add_aoa(eHeaders, [[`Date: From ${moment(new Date(this.starttime.item.start)).format('DD-MM-YYYY')} To ${moment(new Date(this.starttime.item.end)).format('DD-MM-YYYY')}`]], {origin: 'A4'});

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, eHeaders, 'BoostReport') // sheetAName is name of Worksheet
        // export Excel file
        XLSX.writeFile(wb, 'BoostReport'+this.isToday()+'.csv') // name of the file is 'book.xlsx'
        },
    }
}
</script>