<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="800">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Mobile No: {{modalInfo.mobile}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{props.item.loginMobileID}}</td>
                            <td>{{props.item.name}}</td>
                            <td>{{props.item.mobile}}</td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.linkedemail}}</td>
                        </tr>
                    </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: 'Mobile ID', value: 'TicketID' },
                { text: 'Name', value: 'ParkingPaymentTrxRef' },
                { text: 'Mobile No.', value: 'TransactionPaymentDateTime' },
                { text: 'Profile Email', value: 'TransactionPaymentMethod' },
                { text: 'Link Email', value: 'TransactionPayment'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];

            this.tableloading = true;
            this.items.push({
                loginMobileID: this.modalInfo.loginMobileID,
                name: this.modalInfo.name,
                mobile: this.modalInfo.mobile,
                email: this.modalInfo.email,
                linkedemail: this.modalInfo.linkedemail
            });
            this.tableloading = false;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
        },
    }
  }
</script>