<template>
 <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
        <v-card
            outlined
        >
            <v-card-title>
                <v-chip class="ma-2">
                    <v-icon>fa fa-exclamation</v-icon>
                    </v-chip>
                    Page Not Found
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <p>
                            Oops we couldn't find that page. Try going <v-btn text depressed x-small color="blue" :to="{name: 'Home'}">Home</v-btn>.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            </v-card-text>
        </v-card>
    </v-layout>
 </v-container>
</template>

<script>
export default {
    name: 'notFound'
}
</script>