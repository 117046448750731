<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Manual Exit</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                    </v-overlay>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.parkingentryid.item"
                        @keyup.prevent="searchTimeOut"
                        :rules="form.parkingentryid.rule"
                        label="Entry ID *"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.parkingtokenamountmyr.item"
                        :rules="form.parkingtokenamountmyr.rule"
                        label="Amount To Deduct (RM) *"
                        hint="Amount (RM) to be deducted from Parking Tokens upon manual exit."
                        persistent-hint
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.finalparkingamountmyr.item"
                        :rules="form.finalparkingamountmyr.rule"
                        label="Total Amount (RM) *"
                        hint="Total Amount (RM) of amount PAID before exit and amount to be deducted."
                        persistent-hint
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.valuepasspassid.item"
                        :rules="form.valuepasspassid.rule"
                        label="Unit ID Value Pass"
                        hint="Leave it blank if manual exit without utilize any value pass."
                        persistent-hint
                        class="mb-2"
                    ></v-text-field>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Exit Gate"
                    label="Exit Gate *"
                    v-model="form.exitgateid.selected"
                    :items="form.exitgateid.items"
                    item-text="name"
                    item-value="GateID"
                    :rules="form.exitgateid.rule"
                    :loading="form.exitgateid.loader"
                    :disabled="form.exitgateid.disable"
                    >
                    </v-autocomplete>
                    <vc-date-picker v-model="form.requestedticketexitdatetime.item" :disabled-dates='disabled_dates' popover mode="datetime" locale="en-GB" :max-date='new Date()'>
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <div
                                v-bind:class="validateStartDateMain"
                                class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                <div class="v-input__control">
                                <div class="v-input__slot white">
                                    <fieldset aria-hidden="true"><legend :style="focused || form.requestedticketexitdatetime.item ? {'width': '49px'} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                                    <div class="v-text-field__slot">
                                    <label for="input-startdate" v-bind:class="validateStartDate" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Exit Date *</label>
                                    <input
                                    id="input-startdate"
                                    @focus="focused = true"
                                    @blur="focused = false"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    @click="togglePopover({ placement: 'top-start', visibility: 'click', positionFixed: true })"
                                    autocomplete="off"
                                    />
                                    </div>
                                </div>
                                <div class="v-text-field__details">
                                    <v-input
                                        error
                                        readonly
                                        v-model="form.requestedticketexitdatetime.item"
                                        :rules="form.requestedticketexitdatetime.rule"
                                        height="0"
                                        class="hide-input-text"
                                    >
                                    </v-input>
                                </div>
                                </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Category *"
                    v-model="form.category.selected"
                    :items="form.category.items"
                    @change="execFilter('category')"
                    item-text="name"
                    item-value="ID"
                    :rules="form.category.rule"
                    :loading="form.category.loader"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Issue *"
                    v-model="form.issue.selected"
                    :items="form.issue.items"
                    item-text="name"
                    item-value="ID"
                    :rules="form.issue.rule"
                    :loading="form.issue.loader"
                    >
                    </v-autocomplete>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.reasonformanualexit.item"
                        :rules="form.reasonformanualexit.rule"
                        label="Reason *"
                    ></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'allInfo', 'category', 'issue'],

    data() {
        return {
            dialogLocal: false,
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                parkingentryid: {item: "", rule: []},
                parkingtokenamountmyr: {item: "", rule: []},
                valuepasspassid: {item: "", rule: []},
                finalparkingamountmyr: {item: "", rule: []},
                exitgateid: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                requestedticketexitdatetime: {item: "", formatted: "", rule: []},
                category: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                issue: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                reasonformanualexit: {item: "", rule: []},
            },
            modal_loader: false,
            focused: false,
            disabled_dates: []
        }
    },

    components: {alertbox, VueTimepicker},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.form.requestedticketexitdatetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.requestedticketexitdatetime.item == '' && this.form.requestedticketexitdatetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.requestedticketexitdatetime.item == '' && this.form.requestedticketexitdatetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getGateFull(){
            let list = [];
            this.form.exitgateid.items = [];
            this.form.exitgateid.selected = "";

            try{
                this.form.exitgateid.loader = true;
                this.form.exitgateid.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getGateDropDown", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form.exitgateid.items.push({name: "Select Exit Gate", GateID: "", CarParkID: ""});
                    this.form.exitgateid.itemsFull.push({name: "Select Exit Gate", GateID: "", CarParkID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].IsEntryGate === 0){
                        dt[i].name = `${dt[i].GateID} - ${dt[i].RefGateID}`;
                        this.form.exitgateid.items.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID});
                        this.form.exitgateid.itemsFull.push({name: dt[i].name, GateID: dt[i].RefGateID, CarParkID: dt[i].CarParkID});
                    }
                }
                }

                this.form.exitgateid.loader = false;
                this.form.exitgateid.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async beforeCreateForm(flag){
            this.form.parkingentryid.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.parkingtokenamountmyr.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.finalparkingamountmyr.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.valuepasspassid.rule = [
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form.exitgateid.rule = [
                v => !!v || `This is required`,
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            let isValidDate = false;
            if (this.form.requestedticketexitdatetime.item !== ""){
                let entryidfilter = this.allInfo.find(item => item.ParkingEntryID == this.form.parkingentryid.item);
                isValidDate = moment(this.form.requestedticketexitdatetime.item).isAfter(moment(new Date(moment(entryidfilter.ParkingEntryDateTimeOri).format('MM/DD/YYYY HH:mm:ss'))));
            }

            this.form.requestedticketexitdatetime.rule = [
                v => !!v || `This is required`,
                v => isValidDate || `The exit date time must be after entry date time`
            ];

            this.form.reasonformanualexit.rule = [
                v => !!v || `This is required`,
            ];

            this.form.exitgateid.rule = [
                v => !!v || `This is required`,
            ];

            this.form.category.rule = [
                v => !!v || `This is required`,
            ];

            this.form.issue.rule = [
                v => !!v || `This is required`,
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            await this.getGateFull();
            this.disabled_dates = [];
            this.form.parkingentryid.rule = []; this.form.parkingtokenamountmyr.rule = []; this.form.valuepasspassid.rule = []; this.form.finalparkingamountmyr.rule = [];
            this.form.exitgateid.rule = []; this.form.requestedticketexitdatetime.rule = []; this.form.reasonformanualexit.rule = [];
            this.form.category.rule = []; this.form.issue.rule = [];

            this.form.parkingentryid.item = "";
            this.form.parkingtokenamountmyr.item = "";
            this.form.valuepasspassid.item = "";
            this.form.finalparkingamountmyr.item = "";
            this.form.exitgateid.item = "";
            this.form.requestedticketexitdatetime.item = "";
            this.form.requestedticketexitdatetime.formatted = "";
            this.form.reasonformanualexit.item = "";
            this.form.category.selected = "";
            this.form.issue.selected = "";
            this.form.category.items = this.category;
            this.form.category.itemsFull = this.category;
            this.form.issue.items = this.issue;
            this.form.issue.itemsFull = this.issue;
        },

        filterGate(){
            this.form.exitgateid.selected = "";
            if (this.form.parkingentryid.item !== ""){
                let items = [];
                items.push({name: "Select Exit Gate", GateID: "", CarParkID: ""});

                let entryidfilter = this.allInfo.find(item => item.ParkingEntryID == this.form.parkingentryid.item);
                if (entryidfilter != undefined){
                    let filtered = this.form.exitgateid.itemsFull.filter(item => item.CarParkID == entryidfilter.CarParkID);

                    if (filtered.length > 0){
                        for(var i=0; i<filtered.length; i++){
                            items.push({name: filtered[i].name, GateID: filtered[i].GateID, CarParkID: filtered[i].CarParkID});
                        }
                    }   
                }

                this.form.exitgateid.items = items;
            }

            else{
                this.form.exitgateid.items = this.form.exitgateid.itemsFull;
            }

            this.form.exitgateid.loader = false;
            this.form.exitgateid.disable = false;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
            let formattedrequestedticketexitdatetime = moment(this.form.requestedticketexitdatetime.item).format('YYYY-MM-DD HH:mm:ss');
            this.form.requestedticketexitdatetime.formatted = formattedrequestedticketexitdatetime;

                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        execFilter(id){
            if (id !== 'category') return;

            this.form.issue.selected = "";

            if (this.form.category.selected !== ''){
                let [first, ...rest] = this.form.issue.itemsFull;
                console.log('this.form.issue.itemsFull', this.form.issue.itemsFull);
                let issue = this.form.issue.itemsFull.filter(itm => itm.CategoryID === this.form.category.selected);
                issue.unshift(first);
                console.log('issue', issue);
                this.form.issue.items = issue;
            }

            else{
                let [...issue] = this.form.issue.itemsFull;
                console.log('issue', issue);
                this.form.issue.items = issue;
            }
        },

        searchTimeOut() {
          this.form.exitgateid.loader = true;
          this.form.exitgateid.disable = true;
          this.form.requestedticketexitdatetime.item = "";
          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.filterGate();
          let entryidfilter = this.allInfo.find(item => item.ParkingEntryID == this.form.parkingentryid.item);
          console.log('entryidfilter', entryidfilter);
          if (entryidfilter != undefined){
              let dte = new Date(moment(entryidfilter.ParkingEntryDateTimeOri).subtract('1', 'days').format('MM/DD/YYYY HH:mm:ss'));
              console.log('entryidfilter.ParkingEntryDateTimeOri', entryidfilter.ParkingEntryDateTimeOri);
            this.disabled_dates = [
                {
                start: null,
                end: dte
                },
            ];
          }
          }, 800);
        }
    }
  }
</script>