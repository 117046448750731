<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">BL Park ID: {{modalInfo.ParkingWheelLockID}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay
                :absolute="true"
                :opacity="1"
                :z-index="999"
                :value="modal_loader"
                color="#D2D7DF"
                >
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-overlay>
            <v-row v-if="items.length > 0">
                <!--
                    BL Park ID, Trxn ID, CP Code, CP ID, Car Park, Mobile No., Date of Entry, BayID, Bay Number, Parking Duration,  Exit Method, Total Parking Tokens, Pending Paid (RM), Next 2 Hours Fees (RM)
                -->
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ParkingWheelLockID"
                        label="BL Park ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].TrxnID"
                        label="Trxn ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CarParkUserInputCode"
                        label="CP Code"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].carpark"
                        label="Car Park"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.mobile"
                        label="Mobile No."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ParkingEntryDateTime"
                        label="Date of Entry"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.WheelLockID"
                        label="BL ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].BayID"
                        label="Bay ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CarParkBayPrefix"
                        label="CP Prefix"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CarParkBayNo"
                        label="Bay Number"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Duration"
                        label="Parking Duration (dd:hh:mm)"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].exitMethodCustom"
                        label="Exit Method"
                        :class="amount_due_color"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CurrentCustomerAvailableParkingTokens"
                        label="Total Parking Tokens"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Amount_Due"
                        label="Pending Paid (RM)"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Amount_CurrentFees_After2Hours"
                        label="Next 2 Hours Fees"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="12">
                    <span class="text-body-2">No entry found for this parking entry</span>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1,
            modal_loader: true,
            amount_due_color: ''
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listParkedPayment(){
            this.items = [];
            this.tableloading = true;
            this.modal_loader = true;
            this.amount_due_color = "";

            let response = await this.$store.dispatch('getBayLockEntryPaymentDetails', {ParkingWheelLockID: this.modalInfo.ParkingWheelLockID})
            if (response.data.code == "AP000"){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].ParkingEntryDateTime = dt[i].ParkingEntryDateTime == null ? "N/A" : moment(dt[i].ParkingEntryDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].carpark = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                    dt[i].Amount_CurrentFees = Number(dt[i].Amount_CurrentFees).toFixed(2);
                    dt[i].Amount_Paid = Number(dt[i].Amount_Paid).toFixed(2);
                    dt[i].Amount_Due = Number(dt[i].Amount_Due).toFixed(2);
                    dt[i].Amount_CurrentFees_After2Hours = Number(dt[i].Amount_CurrentFees_After2Hours).toFixed(2);

                    if (dt[i].ExitMethod == "PAYMENT"){
                        let ptokentorm = dt[i].CurrentCustomerAvailableParkingTokens * 0.01;
                        console.log(ptokentorm);
                        dt[i].exitMethodCustom = dt[i].Amount_Due;
                        if (dt[i].Amount_Due < ptokentorm)
                        this.amount_due_color = "green-text"

                        else if (dt[i].Amount_Due > ptokentorm)
                        this.amount_due_color = 'red-text';

                        else if (dt[i].Amount_CurrentFees_After2Hours >= ptokentorm)
                        this.amount_due_color = 'orange-text';
                    }

                    if (dt[i].ExitMethod == "SEASON"){
                        dt[i].exitMethodCustom = 'Season';
                        this.amount_due_color = "black-text";
                    }

                    if (dt[i].ExitMethod == "VALUEPASS"){
                        dt[i].exitMethodCustom = dt[i].ValuePassName;
                        this.amount_due_color = "black-text";
                    }
                }

                this.items = dt;
            }

            this.tableloading = false;
            this.modal_loader = false;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listParkedPayment();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>