<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="450">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Mobile No: {{modalInfo.mobile}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-overlay
                :absolute="true"
                :opacity="1"
                :z-index="999"
                :value="modal_loader"
                color="#D2D7DF"
                >
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-overlay>
            <v-row v-if="items.length > 0">
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ParkingEntryID"
                        label="Entry ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].TicketID"
                        label="Ticket ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CarParkUserInputCode"
                        label="CP Code"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].carpark"
                        label="Car Park"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].exitMethodCustom"
                        label="Exit Method"
                        :class="amount_due_color"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CurrentCustomerMobile"
                        label="Mobile No."
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ParkingEntryDateTime"
                        label="Date of Entry"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].GateID"
                        label="Entry Gate"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Duration"
                        label="Parking Duration (dd:hh:mm)"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ValuePassProductID"
                        label="VP ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].SelectedValuePassPassID"
                        label="VP Unit ID"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].ValuePassName"
                        label="Value Pass Name"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CarPlateNo"
                        label="Car Plate"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].CurrentCustomerAvailableParkingTokens"
                        label="Total Parking Tokens"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Amount_CurrentFees"
                        label="Current Fees (RM)"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Amount_Due"
                        label="Pending Paid (RM)"
                        required
                        disabled
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="items[0].Amount_CurrentFees_After2Hours"
                        label="Next 2 Hours Fees"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="12">
                    <span class="text-body-2">No entry found for this parking entry</span>
                </v-col>
            </v-row>
              <!--
            <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ParkingEntryID}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.CurrentCustomerMobile}}</td>
                            <td>{{props.item.ParkingEntryDateTime}}</td>
                            <td>{{props.item.Duration}}</td>
                            <td>{{props.item.ExitMethod}}</td>
                            <td>{{props.item.ValuePassID}}</td>
                            <td>{{props.item.ValuePassUnitID}}</td>
                            <td>{{props.item.ValuePassName}}</td>
                            <td>{{props.item.TotalTokens}}</td>
                            <td>{{props.item.Amount_CurrentFees}}</td>
                            <td>{{props.item.Amount_Due}}</td>
                            <td>{{props.item.Amount_FutureFees}}</td>
                        </tr>
                    </template>
            </v-data-table>
            -->
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'Parking ID', value: 'ParkingEntryID' },
                { text: 'CP ID', value: 'CarParkID' },
                { text: 'Car Park', value: 'CarParkName' },
                { text: 'Mobile No.', value: 'CurrentCustomerMobile'},
                { text: 'Date Of Entry', value: 'ParkingEntryDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Parking Duration (dd:hh:mm)', value: 'Duration' },
                { text: 'Exit Method', value: 'ExitMethod' },
                { text: 'VP ID', value: 'ValuePassID' }, //need to update to actual param
                { text: 'VP Unit ID', value: 'ValuePassUnitID' }, //need to update to actual param
                { text: 'Value Pass Name', value: 'ValuePassName' },
                { text: 'Total Parking Tokens', value: 'TotalTokens' }, //need to update to actual param
                { text: 'Current Fees (RM)', value: 'Amount_CurrentFees' },
                { text: 'Pending Paid (RM)', value: 'Amount_Due' },
                { text: 'Next 2 Hours Fees (RM)', value: 'Amount_FutureFees' }, //need to update to actual param
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1,
            modal_loader: true,
            amount_due_color: ''
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listParkedPayment(){
            this.items = [];
            this.tableloading = true;
            this.modal_loader = true;

            let response = await this.$store.dispatch('getPaymentTicketDetails', {parkingentryid: this.modalInfo.ParkingEntryID})
            if (response.data.code == "AP000"){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].ParkingEntryDateTime = dt[i].ParkingEntryDateTime == null ? "N/A" : moment(dt[i].ParkingEntryDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].carpark = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                    dt[i].ValuePassName = dt[i].ValuePassName == null ? "N/A" : dt[i].ValuePassName;
                    dt[i].ValuePassProductID = dt[i].ValuePassProductID == null ? "N/A" : dt[i].ValuePassProductID;
                    dt[i].SelectedValuePassPassID = dt[i].SelectedValuePassPassID == null ? "N/A" : dt[i].SelectedValuePassPassID;
                    dt[i].Amount_CurrentFees = Number(dt[i].Amount_CurrentFees).toFixed(2);
                    dt[i].Amount_Paid = Number(dt[i].Amount_Paid).toFixed(2);
                    dt[i].Amount_Due = Number(dt[i].Amount_Due).toFixed(2);
                    dt[i].Amount_CurrentFees_After2Hours = Number(dt[i].Amount_CurrentFees_After2Hours).toFixed(2);
                    dt[i].CarPlateNo = dt[i].CarPlateNo || 'N/A';

                    if (dt[i].ExitMethod == "PAYMENT"){
                        let ptokentorm = dt[i].CurrentCustomerAvailableParkingTokens * 0.01;
                        console.log(ptokentorm);
                        dt[i].exitMethodCustom = dt[i].Amount_Due;
                        if (dt[i].Amount_Due < ptokentorm)
                        this.amount_due_color = "green-text"

                        else if (dt[i].Amount_Due > ptokentorm)
                        this.amount_due_color = 'red-text';

                        else if (dt[i].Amount_CurrentFees_After2Hours >= ptokentorm)
                        this.amount_due_color = 'orange-text';
                    }

                    if (dt[i].ExitMethod == "SEASON"){
                        dt[i].exitMethodCustom = 'Season';
                        this.amount_due_color = "black-text";
                    }

                    if (dt[i].ExitMethod == "VALUEPASS"){
                        dt[i].exitMethodCustom = dt[i].ValuePassName;
                        this.amount_due_color = "black-text";
                    }
                }

                this.items = dt;
            }

            this.tableloading = false;
            this.modal_loader = false;
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listParkedPayment();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>