<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Create Virtual Account</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    @change="execFilter('operator')"
                    :items="form.operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    :disabled="form.operator.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park"
                    v-model="form.carpark.selected"
                    @change="execFilter('carpark')"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    >
                    </v-autocomplete>
                     <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Type *"
                    v-model="form.type.selected"
                    @change="execFilter('type')"
                    :items="form.type.items"
                    item-text="name"
                    item-value="id"
                    :rules="form.type.rule"
                    :loading="form.type.loader"
                    :disabled="form.type.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Controller ID - Controller Ref *"
                    v-model="form.controller.selected"
                    :items="form.controller.items"
                    item-text="name"
                    item-value="WLControllerID"
                    :rules="form.controller.rule"
                    :loading="form.controller.loader"
                    :disabled="form.controller.disable"
                    >
                    </v-autocomplete>
                   
                    <v-text-field
                        dense
                        outlined
                        v-model="form.VirtualAccNo.item"
                        :rules="form.VirtualAccNo.rule"
                        label="VA Number *"
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.VirtualAccName.item"
                        :rules="form.VirtualAccName.rule"
                        label="VA Name *"
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.QRID.item"
                        :rules="form.QRID.rule"
                        label="QR ID *"
                        required
                    ></v-text-field>
                    <v-text-field
                        dense
                        outlined
                        v-model="form.Remark.item"
                        :rules="form.Remark.rule"
                        label="Remark"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator', 'controller'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                GateToken: {item: "", rule: []},
                wlmainboardref: {item: "", rule: []},
                TokenRef: {item: ""}, //wait backend
                Operator: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                controller: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                type: {
                    items: [{name: 'Select', id: ''}, {name: 'Gated', id: 'GATED'}, {name: 'Baylock', id: 'WHEELLOCK'}],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                VirtualAccNo: {item: '', rule: []},
                VirtualAccName: {item: '', rule: []},
                QRID: {item: '', rule: []},
                Remark: {item: '', rule: []}
            },
            modal_loader: false,
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.controller.rule = [
                v => !!v || 'This is required',
            ];

            this.form.wlmainboardref.rule = [
                v => !!v || 'This is required',
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form.type.rule = [
                v => !!v || 'This is required',
            ];

            this.form.VirtualAccNo.rule = [
                v => !!v || 'This is required',
            ];

            this.form.VirtualAccName.rule = [
                v => !!v || 'This is required',
            ];

            this.form.QRID.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.form.controller.rule = [];
            this.form.type.rule = [];
            this.form.VirtualAccNo.rule = [];
            this.form.VirtualAccName.rule = [];
            this.form.QRID.rule = [];

            this.form.VirtualAccNo.item = "";
            this.form.VirtualAccName.item = "";

            this.form.QRID.item = "";
            this.form.Remark.item = "";

            this.form.operator.selected = "";
            this.form.type.selected = "";
            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            for (const [index, item] of this.operator.entries()){
                operator.push(item);
            }
            this.form.operator.items = operator;

            this.form.carpark.selected = "";
            let carpark = [];

            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.type.selected = 'GATED';

            this.form.controller.selected = "";
            this.form.controller.items.push({name: `Select`, WLControllerID: ''});
            for (const [index, item] of this.controller.entries()){
                this.form.controller.items.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
            }

            const response = await this.$store.dispatch('getGateController', {rowresultsperpage: null, operatorid: null, carparkid: null, page:1});
            console.log(response.data);
            const data = response.data;
            if(data.code == 'AP000' && data.recordcount > 0){
                this.form.controller.items = [];
                this.form.controller.items.push({name: `Select`, WLControllerID: ''});
                for(const[index, item] of data.record.entries()){
                    console.log('type');
                    this.form.controller.items.push({name: `${item.GateControllerID} - ${item.RefControllerID}`, WLControllerID: item.GateControllerID});
                }
            }
        },

        async execFilter(item){
            if (item == "operator"){
                this.form.carpark.selected = "";
                this.form.controller.selected = "";
                this.form.type.selected = '';
                let filtered = this.carpark.filter(item => item.OperatorID == this.form.operator.selected);
                console.log(filtered);
                let carpark = [];
                carpark.push({name: 'Select', CarParkID: ''});
                for (const [index, item] of filtered.entries()){
                    carpark.push({name: `${item.CarParkID} - ${item.CarParkName}`, CarParkID: item.CarParkID});
                }

                let filteredcarparkfromoperatorid = this.controller.filter(item => item.OperatorID == this.form.operator.selected);
                let controller = [];
                controller.push({name: 'Select', WLControllerID: ''});
                for (const [index, item] of filteredcarparkfromoperatorid.entries()){
                    controller.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                }

                if (this.form.operator.selected != ""){
                    this.form.carpark.items = carpark;
                    this.form.controller.items = controller;
                }

                else{
                    for (const [index, item] of this.carpark.entries()){
                        this.form.carpark.items.push({name: `${item.CarParkID} - ${item.CarParkName}`, CarParkID: item.CarParkID});
                    }

                    for (const [index, item] of this.controller.entries()){
                        this.form.controller.items.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                    }
                }
            }

            if (item == "carpark"){
                this.form.controller.selected = "";
                let controller = [];
                controller.push({name: 'Select', WLControllerID: ''});
                this.form.type.selected ='';
                if (this.form.carpark.selected != ""){
                    let filteredcarparkfromoperatorid = this.controller.filter(item => item.CarParkID == this.form.carpark.selected);
                    for (const [index, item] of filteredcarparkfromoperatorid.entries()){
                        controller.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                    }

                    this.form.controller.items = controller;
                }

                else if (this.form.carpark.selected == ""){
                    if (this.form.operator.selected == ""){
                        for (const [index, item] of this.controller.entries()){
                            this.form.controller.items.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                        }
                    }

                    else{
                        let filteredcarparkfromoperatorid = this.controller.filter(item => item.OperatorID == this.form.operator.selected);
                        for (const [index, item] of filteredcarparkfromoperatorid.entries()){
                            controller.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                        }

                        this.form.controller.items = controller;
                    }
                }
            }

            if(item == 'type'){
                if(this.form.type.selected == 'GATED' ){
                    if(this.form.carpark.selected != ''){
                        if(this.form.operator.selected != ''){
                            // getGateController
                            const response = await this.$store.dispatch('getGateController', {rowresultsperpage: null, operatorid: this.form.operator.selected, carparkid: this.form.carpark.selected, page:1});
                            console.log(response.data);
                            const data = response.data;
                            if(data.code == 'AP000' && data.recordcount > 0){
                                this.form.controller.items = [];
                                this.form.controller.items.push({name: `Select`, WLControllerID: ''});
                                for(const[index, item] of data.record.entries()){
                                    console.log('type');
                                    this.form.controller.items.push({name: `${item.GateControllerID} - ${item.RefControllerID}`, WLControllerID: item.GateControllerID});
                                }
                            }
                        } else {
                            const response = await this.$store.dispatch('getGateController', {rowresultsperpage: null, operatorid: null, carparkid: null, page:1});
                            console.log(response.data);
                            const data = response.data;
                            if(data.code == 'AP000' && data.recordcount > 0){
                                this.form.controller.items = [];
                                this.form.controller.items.push({name: `Select`, WLControllerID: ''});
                                for(const[index, item] of data.record.entries()){
                                    console.log('type');
                                    this.form.controller.items.push({name: `${item.GateControllerID} - ${item.RefControllerID}`, WLControllerID: item.GateControllerID});
                                }
                            }
                        }
                    } else {
                        const response = await this.$store.dispatch('getGateController', {rowresultsperpage: null, operatorid: null, carparkid: null, page:1});
                        console.log(response.data);
                        const data = response.data;
                        if(data.code == 'AP000' && data.recordcount > 0){
                            this.form.controller.items = [];
                            this.form.controller.items.push({name: `Select`, WLControllerID: ''});
                            for(const[index, item] of data.record.entries()){
                                console.log('type');
                                this.form.controller.items.push({name: `${item.GateControllerID} - ${item.RefControllerID}`, WLControllerID: item.GateControllerID});
                            }
                        }
                    }
                } else {
                    this.form.controller.items = [];
                    this.form.controller.items.push({name: 'Select', WLControllerID: ''});
                    for (const [index, item] of this.controller.entries()){
                        this.form.controller.items.push({name: `${item.WLControllerID} - ${item.WLControllerRef}`, WLControllerID: item.WLControllerID});
                    }

                }
            }
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
  }
</script>