<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="1250">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">View Car Plate : {{modalInfo.mobile}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>
                <image-zoom v-if="items.length > 0 && items[0] !== null && items[0].ImageUrl" 
                    img-class="height1"
                    class="contain1"
                    :regular="items[0].ImageUrl"
                >
                </image-zoom>
            </div>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'Car Plate', value: 'CarRegNo' },
                { text: 'Description', value: 'Description' },
                { text: 'Date Created', value: 'CreatedDateTime', width: '30%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Date Updated', value: 'UpdatedDateTime', width: '30%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async listAll(){
            this.items = [];

            this.tableloading = true;
            console.log(this.modalInfo);
            let response = await this.$store.dispatch('getLprCarplate', {carplateNo: this.modalInfo.CarPlateNo, carparkid: this.modalInfo.CarParkID, imageType: this.modalInfo.ImageType})
            if (response){
                let dt = response.data;
                console.log(dt);
                this.items = dt;
                // for(var i=0; i<dt.length; i++){
                //     if (dt[i].DeletedDateTime === null){
                //         dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                //         dt[i].Description = dt[i].Description || 'N/A';
                //         dt[i].CreatedDateTime = moment(dt[i].CreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                //         dt[i].UpdatedDateTime = dt[i].UpdatedDateTime == null ? "N/A" : moment(dt[i].UpdatedDateTime).format('DD-MM-YYYY hh:mm:ss A');

                //         this.items.push({
                //             row: dt[i].row,
                //             ID: dt[i].ID,
                //             PlateNo: dt[i].PlateNo,
                //             ImageUrl: dt[i].ImageUrl,
                //             CreatedDateTime: dt[i].CreatedDateTime,
                //             UpdatedDateTime: dt[i].UpdatedDateTime
                //         });
                //     }
                // }
                
                this.tableloading = false;
            }
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.listAll();
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>

<style>
.contain1 {
  width: 1200px !important;
  height: 700px !important;
}
.vh--holder {
  height: calc(100%);
}
.height1{
  width: 100%;
  height: 750px;
}
</style>